import React, { Component } from 'react'
import Select from "react-select";
import { sweetalert } from "../../App";
import { buildRow, getLabel, getLabelsForMultiSelect } from "../../util/FormatUtil";
import { AiOutlineDown, AiOutlineUp, AiOutlineCloudDownload } from "react-icons/ai";
import SystemAPI from "../../network/SystemAPI";
import PhoneInput from 'react-phone-number-input';
import moment from "moment";
import SamplesAPI from '../../network/SamplesAPI';
import { withTranslation } from 'react-i18next';


interface ExemptionModalState {
  selectedExemption
  customFieldData?
  ethnicities?
  genders?
  races?
  immunizations?
  exemptionArrowDown?
  childArrowDown?
  parentArrowDown?
  schoolArrowDown?
  fileUploadArrowDown?
  gradeOptions?
  showLoading?
  showInBetween?
  relationships?
  exemptionTypes?
  districts?
  schools?
  EducationSite?
  schoolOrChildCare?
  childCareFacilities?
  exemptionReasons?
}
interface ExemptionModalProps {
  selectedExemption
  immunizations
  t
  onClose
}


class ExemptionRecordModal extends Component<ExemptionModalProps, ExemptionModalState> {
  public static ID = "ExemptionRecordModal";

  constructor(props) {
    super(props);

    this.state = {
      selectedExemption: {},
      gradeOptions: [
        { label: "Headstart", value: "Headstart" },
        { label: "Pre-K", value: "Pre-K" },
        { label: "Kindergarten", value: "Kindergarten" },
        { label: "1st", value: "1st" },
        { label: "2nd", value: "2nd" },
        { label: "3rd", value: "3rd" },
        { label: "4th", value: "4th" },
        { label: "5th", value: "5th" },
        { label: "6th", value: "6th" },
        { label: "7th", value: "7th" },
        { label: "8th", value: "8th" },
        { label: "9th", value: "9th" },
        { label: "10th", value: "10th" },
        { label: "11th", value: "11th" },
        { label: "12th", value: "12th" }
      ]
    };

  }

  componentDidMount(): void {
    SystemAPI.getAllEthnicities().then(data => {
      this.setState({ ethnicities: data })
    })
    SystemAPI.getAllGenders().then(data => {
      this.setState({ genders: data })
    })
    SystemAPI.getAllRaces().then(data => {
      this.setState({ races: data })
    })
    SystemAPI.getAllImmunizations().then(data => {
      this.setState({ immunizations: data })
    })
    SystemAPI.getAllRelationships().then(data => {
      this.setState({ relationships: data })
    })
    SystemAPI.getAllExemptionTypes().then(data => {
      this.setState({ exemptionTypes: data })
    })
    SystemAPI.getAllPersonalExemptionReasons().then(data => {
      this.setState({ exemptionReasons: data })
    })
    SystemAPI.getAllSchoolDistricts().then(data => {
      this.setState({ districts: data })
    })
    SystemAPI.getAllSchools().then(data => {
      let options = data.schools.map(s => {
        //@ts-ignore
        return { label: s.SchoolName, value: s.ID }
      })
      this.setState({ schools: options })
    })
    SystemAPI.getAllChildCareFacilities().then(data => {
      //@ts-ignore
      this.setState({ childCareFacilities: data.facilities.map((facility) => {return { label: facility.ChildCareSiteName, value: facility.ID }}) })
    })
  }


  componentWillReceiveProps(nextProps: Readonly<ExemptionModalProps>, nextContext: any): void {
    this.setState({
      selectedExemption: {
        ...this.props.selectedExemption,
        fileNames: this.props.selectedExemption && this.props.selectedExemption.FileUploadPaths ? this.getFileNames(this.props.selectedExemption.FileUploadPaths) : [],
        ChildRaceID: this.props.selectedExemption && this.props.selectedExemption.ChildRaceID ? JSON.parse(this.props.selectedExemption.ChildRaceID) : null,
        EducationSite: this.props.selectedExemption && this.props.selectedExemption.EducationSite ? JSON.parse(this.props.selectedExemption.EducationSite) : null
      }
    })
  }

  getFileNames(urlArray) {
    let parsedArray = Array.from(JSON.parse(urlArray));
    let namesOnly = [];
    parsedArray.map(f => {
      //@ts-ignore
      let temp = f.split('/').pop();
      namesOnly.push(temp)
    })
    return namesOnly

  }

  getRacesList() {
    if (this.state.selectedExemption?.ChildRaceID?.length < 2) {
      return getLabel(parseInt(this.state.selectedExemption?.ChildRaceID), this.state.races, this.props.t)?.label
    } else {
      let raceArray = this.state.selectedExemption?.ChildRaceID;
      let raceStringArray = [];
      for (let val of raceArray) {
        raceStringArray.push(getLabel(parseInt(val), this.state.races, this.props.t).label);
      }
      let raceString = raceStringArray.join(", ");
      return raceString;
    }
  }


  downloadFile = async (name) => {
    this.setState({ showInBetween: true })
    let file = await SamplesAPI.downloadFilesFromCloud({ ReqNum: this.state.selectedExemption?.ReqNum, fileName: name })
    try {
      if (typeof file === "object") {
        sweetalert.fire({
          icon: "error",
          title: "",
          text: this.props.t("File not found"),
        });
        this.setState({ showInBetween: false });
        return;
      } else {
        const link = document.createElement("a");
        link.href = file;
        link.download = `${name}`;
        link.click();
        this.setState({ showInBetween: false })
      }
    } catch (error) {
      console.error('error downloading file', error)
      this.setState({ showInBetween: false })
      return sweetalert.fire({ icon: 'error', title: '', text: this.props.t('Could not download file. Please try again.') })
    }
  }


  render() {

    let translationExemptionTypes = this.state.exemptionTypes && this.state.exemptionTypes.length > 0 ? this.state.exemptionTypes.map(e => { return { label: this.props.t(e.label), value: e.value } }) : [];

    return (
      <div
        className="modal fade form_modal" id={ExemptionRecordModal.ID} tabIndex={-1} role="dialog" aria-labelledby="result_modal_label" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-xl col-8 px-0" role="document">
          <div className="modal-content">
            <div className="container-fluid" >
              <div className="modal-header">
                <h5 className="modal-title" id="result_modal_label">{this.props.t("Exemption Record")}</h5>
                <button type="button" className="close" data-dismiss="modal" onClick={() => this.props.onClose()} aria-label={this.props.t("Close")}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body p-0">
                <section className='my-2 mx-auto col-11'>
                  {buildRow(this.props.t("Status"),
                    <input className={"form-control col-12 mx-auto"}
                      maxLength={50}
                      readOnly={this.state.selectedExemption?.ID != null}
                      autoComplete={"off"}
                      type={"search"}
                      name={"Result"}
                      aria-label={this.props.t('Status')}
                      value={this.state.selectedExemption?.Status && new Date() > new Date(this.state.selectedExemption?.PatientNotifiedDate) ? this.props.t("Approved") : this.state.selectedExemption?.Status == null || new Date() < new Date(this.state.selectedExemption?.PatientNotifiedDate) ? this.props.t("Pending") : this.props.t("Denied")}
                    />
                  )}
                  {
                    this.state.selectedExemption?.ExemptionStatus?.value == "2" ?
                      buildRow(this.props.t(`Reason for Denial`),
                        <input
                          id="StatusDate"
                          min={'1000-01-01'}
                          max={'9999-12-31'}
                          className={"form-control col-12 mx-auto"}
                          autoComplete={"off"}
                          type={"search"}
                          readOnly={true}
                          name={"Reason for Denial"}
                          aria-label={this.props.t("Reason for Denial")}
                          value={this.props.t(this.state.selectedExemption?.DenialReason)}
                        />
                      )
                      : <></>}
                  {buildRow(this.props.t("Confirmation Code"),
                    <input className={"form-control col-12 mx-auto"}
                      maxLength={50}
                      readOnly={this.state.selectedExemption?.ID != null}
                      autoComplete={"off"}
                      type={"search"}
                      name={"Result"}
                      aria-label={this.props.t('Confirmation Code')}
                      value={this.state.selectedExemption?.ReqNum}
                    />
                  )}
                </section>
                <div className="card">
                  <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#exemptionXLCollapse" onClick={() => this.setState({ exemptionArrowDown: !this.state.exemptionArrowDown, })} aria-expanded="false" aria-controls="exemptionXLCollapse" >
                    <div className="row justify-content-between px-3">
                      <div className="">
                        <h4>{this.props.t("Exemption")}</h4>
                      </div>
                      <div className="col-2">
                        {this.state.exemptionArrowDown ? (
                          <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#exemptionXLCollapse" role="button" aria-expanded="false" aria-controls="exemptionXLCollapse" />
                        ) : (
                          <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#exemptionXLCollapse" role="button" aria-expanded="false" aria-controls="exemptionXLCollapse" />
                        )}
                      </div>
                    </div>
                  </button>
                  <div className="card-body collapse" id={"exemptionXLCollapse"} >
                    <div className="row">
                      <div className="col-12 col-xl-6">
                        {buildRow(this.props.t("Exemption Type"),
                          <Select
                            id='ExemptionType'
                            isSearchable={true}
                            placeholder={<div className="accessibilityText">Please Select...</div>}
                            noOptionsMessage={() => this.props.t("No option")}
                            isDisabled={this.state.selectedExemption?.ID != null}
                            value={getLabel(this.state.selectedExemption.ExemptionType, this.state.exemptionTypes, this.props.t)}
                            onChange={(e) => this.setState((prevState) => ({
                              selectedExemption: {
                                ...prevState.selectedExemption,
                                ExemptionType: e.value
                              }
                            }))}
                            className={"state_select"}
                            aria-label={this.props.t('Exemption Type')}
                            options={translationExemptionTypes}
                          />
                        )}
                        {buildRow(this.props.t("Immunizations for Exemption"),
                          <Select
                            id='ExemptImmunizations'
                            isSearchable={true}
                            isMulti={true}
                            placeholder={<div className="accessibilityText">{this.props.t("Please Select...")}</div>}
                            noOptionsMessage={() => this.props.t("No option")}
                            isDisabled={this.state.selectedExemption?.ID != null}
                            value={this.state.selectedExemption.ExemptImmunizations ? getLabelsForMultiSelect(JSON.parse(this.state.selectedExemption.ExemptImmunizations), this.state.immunizations) : null}
                            className={"state_select"}
                            aria-label={this.props.t('Immunizations for Exemption')}
                            options={translationExemptionTypes}
                          />, "Immunizations for Exemption"
                        )}
                        {this.state.selectedExemption?.ExemptionType === 5 ?
                          <>
                            {buildRow(this.props.t("Child Harmful Health Condition"),
                              <input
                                className={"form-control"}
                                maxLength={50}
                                readOnly={this.state.selectedExemption?.ID != null}
                                autoComplete={"off"}
                                type={"search"}
                                name={"Result"}
                                aria-label={this.props.t('Child Harmful Health Condition')}
                                value={this.props.t(this.state.selectedExemption?.HarmfulCondition)}
                              />
                            )}
                          </> : <></>
                        }
                      </div>
                      <div className="col-12 col-xl-6">
                        {this.state.selectedExemption?.ExemptionType === 5 ?
                          <>

                          </>
                          :
                          this.state.selectedExemption?.ExemptionType === 2 ?
                            <>
                            </>
                            :
                            this.state.selectedExemption?.ExemptionType === 1 ?
                              <>
                                {buildRow(this.props.t("Reason"),
                                  <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">{this.props.t("Please Select...")}</div>}
                                    noOptionsMessage={() => this.props.t("No option")}
                                    isDisabled={this.state.selectedExemption?.ID != null}
                                    value={this.state.selectedExemption.PersonalExemptionReason ? getLabel(this.state.selectedExemption.PersonalExemptionReason, this.state.exemptionReasons, this.props.t) : null}
                                    className={"state_select"}
                                    aria-label={this.props.t('Reason')}
                                    options={this.state.exemptionReasons}
                                  />
                                )}
                                {this.state.selectedExemption.PersonalExemptionReason === 7 ? buildRow(this.props.t("Summary of Objection"),
                                  <div>
                                    <textarea
                                      className={"form-control mr-3"}
                                      maxLength={600}
                                      autoComplete={"off"}
                                      name={"SummaryOfObjection"}
                                      disabled={this.state.selectedExemption?.ID != null}
                                      value={this.state.selectedExemption?.ObjectionSummary}
                                      aria-label={this.props.t("Summary of Objection")}
                                    />
                                    <div className="row pt-1 pr-3 justify-content-end">
                                      <div className={this.state.selectedExemption?.ObjectionSummary && this.state.selectedExemption?.ObjectionSummary.length > 0 ? 'visible' : 'invisible'}>
                                        <div style={{ fontSize: '0.8em' }}>{this.state.selectedExemption?.ObjectionSummary && this.state.selectedExemption?.ObjectionSummary.length}/600</div>
                                      </div>
                                    </div>
                                  </div>
                                ) : <></>}
                              </> : <></>}
                        {buildRow(this.props.t("Created Date"),
                          <input className={"form-control"}
                            maxLength={50}
                            readOnly={this.state.selectedExemption?.ID != null}
                            autoComplete={"off"}
                            type={"search"}
                            name={"CreatedDate"}
                            aria-label={this.props.t("Created Date")}
                            value={this.state.selectedExemption?.CreatedDate ? moment(this.state.selectedExemption?.CreatedDate).utc().format("MM-DD-YYYY hh:mm a") : null}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/*Physician */}
                {this.state.selectedExemption?.ExemptionType === 5 && <div className="card">
                  <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#fileUploadXLCollapse" onClick={() => this.setState({ fileUploadArrowDown: !this.state.fileUploadArrowDown })} aria-expanded="false" aria-controls="fileUploadXLCollapse" >
                    <div className="row justify-content-between px-3">
                      <div className="">
                        <h4>{this.props.t("Physician")}</h4>
                      </div>
                      <div className="col-2">
                        {this.state.fileUploadArrowDown ? (
                          <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#fileUploadXLCollapse" role="button" aria-expanded="false" aria-controls="fileUploadXLCollapse" />
                        ) : (
                          <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#fileUploadXLCollapse" role="button" aria-expanded="false" aria-controls="fileUploadXLCollapse" />
                        )}
                      </div>
                    </div>
                  </button>
                  <div className="card-body collapse" id={"fileUploadXLCollapse"} >
                    {(
                      <section className="row">
                        <section className="col-12 col-xl-6">
                          {buildRow(this.props.t("Name"),
                            <input
                              className={"form-control"}
                              maxLength={50}
                              readOnly={this.state.selectedExemption?.ID != null}
                              autoComplete={"off"}
                              type={"search"}
                              name={"Result"}
                              aria-label={this.props.t('Physician Name')}
                              value={`${this.state.selectedExemption?.PhysicianFirstName} ${this.state.selectedExemption?.PhysicianLastName}`}
                            />
                          )}
                          {buildRow(this.props.t("Address"),
                            <input
                              className={"form-control"}
                              maxLength={50}
                              readOnly={this.state.selectedExemption?.ID != null}
                              autoComplete={"off"}
                              type={"search"}
                              name={"Result"}
                              aria-label={this.props.t("Physician Address")}
                              value={this.state.selectedExemption?.PhysicianAddress}
                            />
                          )}
                          {buildRow(this.props.t('Apartment/Suite/Building #'),
                            <input
                              id={'SecondaryAddressLine'}
                              className={"form-control"}
                              maxLength={50}
                              readOnly={true}
                              autoComplete={"off"}
                              type={"search"}
                              name={'SecondaryAddressLine'}
                              aria-label={this.props.t("Apartment/Suite/Building #")}
                              value={this.state.selectedExemption?.PhysicianAdditionalAddressInfo ? this.state.selectedExemption?.PhysicianAdditionalAddressInfo : ""}
                            />, this.props.t('Apartment/Suite/Building #')
                          )}
                          {buildRow(this.props.t("Physician City"),
                            <input
                              className={"form-control"}
                              maxLength={50}
                              readOnly={this.state.selectedExemption?.ID != null}
                              autoComplete={"off"}
                              type={"search"}
                              name={"Result"}
                              aria-label={this.props.t("Physician City")}
                              value={this.state.selectedExemption?.PhysicianCity}
                            />
                          )}
                          {buildRow(this.props.t("State"),
                            <input
                              className={"form-control"}
                              maxLength={50}
                              readOnly={this.state.selectedExemption?.ID != null}
                              autoComplete={"off"}
                              type={"search"}
                              name={"Result"}
                              aria-label={this.props.t("Physician State")}
                              value={this.state.selectedExemption?.PhysicianState}
                            />
                          )}
                        </section>
                        <section className="col-12 col-xl-6">
                          {buildRow(this.props.t("Zipcode"),
                            <input
                              className={"form-control"}
                              maxLength={50}
                              readOnly={this.state.selectedExemption?.ID != null}
                              autoComplete={"off"}
                              type={"search"}
                              name={"Result"}
                              aria-label={this.props.t("Physician Zipcode")}
                              value={this.state.selectedExemption?.PhysicianZip}
                            />
                          )}
                          {buildRow(this.props.t("Office Phone"),
                            <input
                              className={"form-control"}
                              maxLength={50}
                              readOnly={this.state.selectedExemption?.ID != null}
                              autoComplete={"off"}
                              type={"search"}
                              name={"Result"}
                              aria-label={this.props.t("Physician Phone")}
                              value={this.state.selectedExemption?.PhysicianPhone}
                            />
                          )}
                          {buildRow(this.props.t("Email"),
                            <input
                              className={"form-control"}
                              maxLength={50}
                              readOnly={this.state.selectedExemption?.ID != null}
                              autoComplete={"off"}
                              type={"search"}
                              name={"Result"}
                              aria-label={this.props.t("Physician Email")}
                              value={this.state.selectedExemption?.PhysicianEmail}
                            />
                          )}
                          {this.state.selectedExemption?.fileNames && this.state.selectedExemption?.fileNames.length > 0 && this.state.selectedExemption?.fileNames.map((fileName, index) => (
                            <div className="form-group row">
                              <label htmlFor={`SelectedFile${index}`} className="col-12 col-sm-4 col-form-label text-center text-md-left">{this.props.t("Selected File")} #{index + 1}</label>
                              <div className="col-8 col-sm-6 p-0 pt-2 m-0 text-center text-md-left" id={`SelectedFile${index}`}>
                                {fileName}
                              </div>
                              <div className="col-2 col-sm-1">
                                <AiOutlineCloudDownload size={30} onClick={() => this.downloadFile(fileName)} />
                              </div>

                            </div>
                          ))}
                        </section>
                      </section>
                    )
                    }
                  </div>
                </div>}
                {/*Child */}
                <div className="card">
                  <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#childXLCollapse" onClick={() => this.setState({ childArrowDown: !this.state.childArrowDown, })} aria-expanded="false" aria-controls="childXLCollapse">
                    <div className="row justify-content-between px-3">
                      <div className="">
                        <h4>{this.props.t("Child")}</h4>
                      </div>
                      <div className="col-2">
                        {this.state.childArrowDown ? (
                          <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#childXLCollapse" role="button" aria-expanded="false" aria-controls="childXLCollapse" />
                        ) : (
                          <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#childXLCollapse" role="button" aria-expanded="false" aria-controls="childXLCollapse" />
                        )}
                      </div>
                    </div>
                  </button>
                  <div className="card-body collapse" id={"childXLCollapse"}>
                    <div className="row">
                      <div className="col-12 col-xl-6">
                        {buildRow(this.props.t("Name"),
                          <input
                            className={"form-control"}
                            maxLength={50}
                            readOnly={this.state.selectedExemption?.ID != null}
                            autoComplete={"off"}
                            type={"search"}
                            name={"Result"}
                            aria-label={this.props.t("Child Name")}
                            onChange={(e) => {
                              this.setState((prevState) => ({
                                selectedExemption: {
                                  ...prevState.selectedExemption,
                                  Value: e.target.value
                                }
                              }))
                            }}
                            value={this.state.selectedExemption?.ChildMiddleInitial ? `${this.state.selectedExemption?.ChildFirstName} ${this.state.selectedExemption?.ChildMiddleInitial} ${this.state.selectedExemption?.ChildLastName}` : `${this.state.selectedExemption?.ChildFirstName} ${this.state.selectedExemption?.ChildLastName}`}
                          />
                        )}
                        {buildRow(this.props.t("Child's Mother's Maiden Name"),
                          <input
                            className={"form-control"}
                            maxLength={50}
                            readOnly={this.state.selectedExemption?.ID != null}
                            autoComplete={"off"}
                            type={"search"}
                            name={"Result"}
                            aria-label={this.props.t("Child's Mother's Maiden Name")}
                            value={this.state.selectedExemption?.MotherMaidenName}
                          />
                        )}
                        {buildRow(this.props.t("Date of Birth"),
                          <input
                            className={"form-control"}
                            maxLength={50}
                            readOnly={this.state.selectedExemption?.ID != null}
                            autoComplete={"off"}
                            type={"search"}
                            name={"Result"}
                            aria-label={this.props.t("Child Date of Birth")}
                            value={moment(this.state.selectedExemption?.ChildDOB).utc().format("MM-DD-YYYY")}
                          />
                        )}
                        {buildRow(this.props.t("Birth Location"),
                          <input
                            className={"form-control"}
                            maxLength={50}
                            readOnly={this.state.selectedExemption?.ID != null}
                            autoComplete={"off"}
                            type={"search"}
                            name={"Result"}
                            aria-label={this.props.t("Child Birth Location")}
                            value={this.state.selectedExemption?.ChildBirthCountry === "United States" ? `${this.state.selectedExemption?.ChildBirthState}, ${this.state.selectedExemption?.ChildBirthCountry}` : `${this.state.selectedExemption?.ChildBirthCountry}`}
                          />
                        )}
                        {buildRow(this.props.t("Gender"),
                          <input
                            className={"form-control"}
                            maxLength={50}
                            readOnly={this.state.selectedExemption?.ID != null}
                            autoComplete={"off"}
                            type={"search"}
                            name={"Result"}
                            aria-label={this.props.t("Child Gender")}
                            value={getLabel(this.state.selectedExemption?.ChildGenderID, this.state.genders, this.props.t)?.label}
                          />
                        )}
                      </div>
                      <div className="col-12 col-xl-6">
                        {buildRow(this.props.t("Race"),
                          <input
                            className={"form-control"}
                            maxLength={50}
                            readOnly={this.state.selectedExemption?.ID != null}
                            autoComplete={"off"}
                            type={"search"}
                            name={"Result"}
                            aria-label={this.props.t("Child Race")}
                            value={this.state.selectedExemption?.ChildRaceID ? this.getRacesList() : null}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/*Parent/ Guardian */}
                <div className="card">
                  <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#parentXLCollapse" onClick={() => this.setState({ parentArrowDown: !this.state.parentArrowDown, })} aria-expanded="false" aria-controls="parentXLCollapse">
                    <div className="row justify-content-between px-3">
                      <div className="">
                        <h4>{this.props.t("Parent/ Guardian")}</h4>
                      </div>
                      <div className="col-2">
                        {this.state.parentArrowDown ? (
                          <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#parentXLCollapse" role="button" aria-expanded="false" aria-controls="parentXLCollapse" />
                        ) : (
                          <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#parentXLCollapse" role="button" aria-expanded="false" aria-controls="parentXLCollapse" />
                        )}
                      </div>
                    </div>
                  </button>
                  <div className="card-body collapse" id={"parentXLCollapse"}>
                    <div className="row">
                      <div className="col-12 col-xl-6">
                        {buildRow(this.props.t("Name"),
                          <input
                            className={"form-control"}
                            maxLength={50}
                            readOnly={this.state.selectedExemption?.ID != null}
                            autoComplete={"off"}
                            type={"search"}
                            name={"Result"}
                            aria-label={this.props.t("Parent/Guardian Name")}
                            value={this.state.selectedExemption?.ParentMiddleInitial ? `${this.state.selectedExemption?.ParentFirstName} ${this.state.selectedExemption?.ParentMiddleInitial} ${this.state.selectedExemption?.ParentLastName}` : `${this.state.selectedExemption?.ParentFirstName} ${this.state.selectedExemption?.ParentLastName}`}
                          />
                        )}
                        {buildRow(this.props.t("Relationship"),
                          <input
                            className={"form-control"}
                            maxLength={50}
                            readOnly={this.state.selectedExemption?.ID != null}
                            autoComplete={"off"}
                            type={"search"}
                            name={"Result"}
                            aria-label={this.props.t("Parent/Guardian Relationship")}
                            value={this.state.selectedExemption ? getLabel(this.state.selectedExemption.RelationshipID, this.state.relationships, this.props.t)?.label : null}
                          />
                        )}
                        {buildRow(this.props.t("Address"),
                          <input className={"form-control"}
                            maxLength={50}
                            readOnly={this.state.selectedExemption?.ID != null}
                            autoComplete={"off"}
                            type={"search"}
                            name={"Result"}
                            aria-label={this.props.t("Parent/Guardian Address")}
                            value={`${this.state.selectedExemption?.ParentAddress}, ${this.state.selectedExemption?.ParentCity} ${this.state.selectedExemption?.ParentState} ${this.state.selectedExemption?.ParentZip}`}
                          />
                        )}
                        {buildRow(this.props.t('Apartment/Suite/Building #'),
                          <input
                            id={'SecondaryAddressLine'}
                            className={"form-control"}
                            maxLength={50}
                            readOnly={true}
                            autoComplete={"off"}
                            type={"search"}
                            name={'SecondaryAddressLine'}
                            aria-label={this.props.t("Parent/Guardian Apartment/Suite/Building #")}
                            value={this.state.selectedExemption?.ParentAdditionalAddressInfo ? this.state.selectedExemption?.ParentAdditionalAddressInfo : ""}
                          />, this.props.t('Apartment/Suite/Building #')
                        )}
                        {buildRow(this.props.t("Parent County"),
                          <input className={"form-control"}
                            maxLength={50}
                            readOnly={this.state.selectedExemption?.ID != null}
                            autoComplete={"off"}
                            type={"search"}
                            name={"Result"}
                            aria-label={this.props.t("Parent/Guardian County")}
                            value={this.state.selectedExemption?.ParentCounty}
                          />
                        )}
                      </div>
                      <div className="col-12 col-xl-6">
                        {buildRow(this.props.t("Phone"),
                          <input className={"form-control"}
                            maxLength={50}
                            readOnly={this.state.selectedExemption?.ID != null}
                            autoComplete={"off"}
                            type={"search"}
                            name={"Result"}
                            aria-label={this.props.t("Parent/Guardian Phone")}
                            value={this.state.selectedExemption?.ParentPhone}
                          />
                        )}
                        {buildRow(this.props.t("Email"),
                          <input className={"form-control"}
                            maxLength={50}
                            readOnly={this.state.selectedExemption?.ID != null}
                            autoComplete={"off"}
                            type={"search"}
                            name={"Result"}
                            aria-label={this.props.t("Parent/Guardian Email")}
                            value={this.state.selectedExemption?.ParentEmail}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/*School */}
                <div className="card mb-2">
                  <button className="card-header verlag-bold accordionButton w-100" data-toggle="collapse" data-target="#schoolXLCollapse" onClick={() => this.setState({ schoolArrowDown: !this.state.schoolArrowDown, })} aria-expanded="false" aria-controls="schoolXLCollapse">
                    <div className="row justify-content-between px-3">
                      <div className="">
                        <h4>{this.props.t("School")}</h4>
                      </div>
                      <div className="col-2">
                        {this.state.schoolArrowDown ? (
                          <AiOutlineUp className={"float-right"} size={30} data-toggle="collapse" data-target="#schoolXLCollapse" role="button" aria-expanded="false" aria-controls="schoolXLCollapse" />
                        ) : (
                          <AiOutlineDown className={"float-right"} size={30} data-toggle="collapse" data-target="#schoolXLCollapse" role="button" aria-expanded="false" aria-controls="schoolXLCollapse" />
                        )}
                      </div>
                    </div>
                  </button>
                  <div className="card-body collapse" id={"schoolXLCollapse"}>
                    <div className="row">
                      <div className="col-12">
                        {this.state.selectedExemption.EducationSite?.map((site, index) =>
                          <section className='border rounded py-2 px-4 mb-3' style={{ borderColor: "rgba(0, 0, 0, 0.125)" }}>
                            {buildRow(this.props.t('School/ Child Care'),
                              <Select
                                key={'School/Childcare'}
                                id={'School/Childcare'}
                                isSearchable={true}
                                isDisabled={true}
                                placeholder={"Please Select..."}
                                noOptionsMessage={() => "No option"}
                                aria-label="School or Child Care"
                                value={this.state.selectedExemption.EducationSite[index].schoolOrChildCare !== null ? getLabel(this.state.selectedExemption.EducationSite[index].schoolOrChildCare, [{ label: "School", value: 0 }, { label: "Child Care", value: 1 }], this.props.t) : null}
                                className={'state_select'}
                                options={[{ label: "School", value: 0 }, { label: "Child Care", value: 1 }]}
                              />, 'School/ Child Care'
                            )}
                            {this.state.selectedExemption.EducationSite[index]?.schoolOrChildCare === 0 ? <>
                              {buildRow(this.props.t('School District'),
                                <input
                                  id={'SchoolDistrict'}
                                  className={"form-control"}
                                  maxLength={50}
                                  autoComplete={"off"}
                                  readOnly={true}
                                  type={"search"}
                                  name={'SchoolDistrict'}
                                  aria-label={this.props.t("School District")}
                                  value={this.state.selectedExemption.EducationSite[index]?.SchoolDistrict === -1 ? this.state.selectedExemption.EducationSite[index]?.OtherSchoolDistrict : getLabel(this.state.selectedExemption.EducationSite[index]?.SchoolDistrict, this.state.districts).label}
                                />, 'School District'
                              )}

                              {buildRow(this.props.t('Name of School'),
                                <input
                                  id={'SchoolName'}
                                  className={"form-control"}
                                  maxLength={50}
                                  autoComplete={"off"}
                                  readOnly={true}
                                  type={"search"}
                                  name={'SchoolName'}
                                  aria-label={this.props.t("School Name")}
                                  value={this.state.selectedExemption.EducationSite[index]?.School === -1 ? this.state.selectedExemption.EducationSite[index]?.OtherSchool : getLabel(this.state.selectedExemption.EducationSite[index]?.School, this.state.schools).label}
                                />, 'Name Of School'
                              )}
                            </> : this.state.selectedExemption.EducationSite[index].schoolOrChildCare === 1 ?
                              buildRow(this.props.t('Name of Child Care'),
                                <input
                                  id={'ChildCareSite'}
                                  className={"form-control"}
                                  maxLength={50}
                                  autoComplete={"off"}
                                  readOnly={true}
                                  type={"search"}
                                  name={'SchoolName'}
                                  aria-label={this.props.t("Name of Child Care")}
                                  value={this.state.selectedExemption.EducationSite[index]?.ChildCareSite === -1 ? this.state.selectedExemption.EducationSite[index]?.OtherChildCareSite : getLabel(this.state.selectedExemption.EducationSite[index]?.ChildCareSite, this.state.childCareFacilities)?.label}
                                />, 'Name Of Child Care'
                              ) : <></>
                            }
                            {this.state.selectedExemption.EducationSite[index].schoolOrChildCare === 0 && buildRow(this.props.t('Grade'),
                              <Select
                                key={'SchoolGrade'}
                                id={'SchoolGrade'}
                                isSearchable={true}
                                isDisabled={true}
                                placeholder={"Please Select..."}
                                noOptionsMessage={() => "No option"}
                                aria-label="School Grade"
                                value={this.state.selectedExemption.EducationSite[index].SchoolGrade ? getLabel(this.state.selectedExemption.EducationSite[index].SchoolGrade, this.state.gradeOptions, this.props.t) : null}
                                className={'state_select'}
                                options={this.state.gradeOptions}
                              />, 'School Grade of child'
                            )}
                            {this.state.selectedExemption.EducationSite[index].schoolOrChildCare === 0 ?
                              buildRow(this.props.t('School Phone'),
                                <PhoneInput
                                  id={'SchoolPhone'}
                                  placeholder={this.props.t("Enter phone number")}
                                  readOnly={true}
                                  disable={true}
                                  defaultCountry="US"
                                  aria-label={this.props.t('School Phone')}
                                  value={this.state.selectedExemption.EducationSite[index].SchoolPhone} />
                              ) : this.state.selectedExemption.EducationSite[index].schoolOrChildCare === 1 ?
                                buildRow(this.props.t('Childcare Phone'),
                                  <PhoneInput
                                    id={'ChildcarePhone'}
                                    placeholder={this.props.t("Enter phone number")}
                                    readOnly={true}
                                    disable={true}
                                    defaultCountry="US"
                                    aria-label={this.props.t('Childcare Phone')}
                                    value={this.state.selectedExemption.EducationSite[index].SchoolPhone} />
                                ) : <></>
                            }</section>)}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="modal-footer">
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default withTranslation()(ExemptionRecordModal);