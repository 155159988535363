// components/LanguageSwitcher.js
import { useTranslation } from 'react-i18next'
import Select from 'react-select';

const LanguageSwitcher = ({onChange}) => {
    const { t, i18n } = useTranslation();

    const options = [
        { label: 'English', value: 'en' },
        { label: 'Espa\u00F1ol', value: 'es' },
    ];

    const selectOptions = options.map(option => ({
        label: option.label,
        value: option.value,
    }));

    const handleChange = selectedOption => {
        const selectedLanguage = selectedOption.value;
        i18n.changeLanguage(selectedLanguage);
        onChange(selectedLanguage);
      };

    return (
        <Select
            aria-label='Language Selector'
            className={"state_select"}
            options={selectOptions}
            onChange={handleChange}
            defaultValue={options.find(option => option.value === i18n.language)}
        />
    );
};

export default LanguageSwitcher;
