import React, { useContext, useEffect, useRef, useState} from 'react'
import { useForm } from 'react-hook-form';
import {sweetalert} from "../../App";
import UsersAPI from '../../network/UsersAPI';
import SystemAPI from '../../network/SystemAPI';
import InBetweenOverlay from '../InBetweenOverlay';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/AuthContext';


export default function PasswordReset() {
    const [loading, setLoading] = useState(false)
    const [email, setEmail ] = useState(null)
    const [branding, setBranding] = useState(null);
    const [buttonColorHexValue, setButtonColorHexValue] = useState('')

    const { t } = useTranslation();
    const userContext = useContext(AuthContext);
    const emailRef = useRef(userContext ? userContext?.email : null);


    // Form Validations
    const [form, setForm] = useState({})
    const { register, handleSubmit, formState: { errors }, trigger, reset} = useForm({
        mode: "onTouched"
    });
    const [isReset, setReset] = useState(false);
    
    useEffect(() => {
        reset(form);
        setReset(true);
    }, [reset, form]); // <= runs when reset state or form state updates

    useEffect(() => {
        isReset && trigger()
    }, [isReset]) // <= runs when trigger state or isReset state updates

    useEffect(() => {
        setLoading(true)
        let currentURL = window.location.href;
        SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
            setButtonColorHexValue(data.ProductButtonColorHexValue)
            setBranding(data)
            setLoading(false)
        })
        
        setForm({
            email: emailRef.current
        })
    }, []) //<= runs on first load

    const onSubmit = async (data) => {
        try {
            let response = await UsersAPI.sendResetPasswordEmail(null, data.email, branding); 
            if(!response.success){
                return sweetalert.fire({icon:'error',text: response.reason})
            } else {
                sweetalert.fire({ icon: 'success', text: t('Please check your email for instructions.') })
                    .then(() => {
                        // call state and reset form
                        setForm({
                            email: emailRef.current 
                        });
                    })
            }
        }
        catch(e){
            console.error(e);
            return sweetalert.fire({text: e, icon: 'error'});
        }
        setLoading(false);
    }

    const onError = (e) => {
        console.error(e)
    }

    return (
        <>
        <div className={loading ? "d-none" : "container  min-vh-100"}>
            <main id="main-content" tabIndex={-1} aria-label="Password Reset">
            <div className="row justify-content-center mt-5">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                                <form onSubmit={(e) => handleSubmit(onSubmit,onError)(e).catch((e)=>{
                                    console.error(' catch ',e)
                                })}>
                                    <div className="mb-4">
                                        <label htmlFor="email" className="form-label">{t("Username/Email")}</label>
                                        <input type="text" className="form-control" 
                                            id="email" name="email"
                                            {...register("email",{
                                                required:t("Username/Email is required"),
                                                pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                    message: t('Please verify email syntax.')
                                                },
                                            })}
                                            onChange={(e)=>{
                                                setEmail(e.target.value.trim())
                                                setForm({...form, 'email':e.target.value.trim() })
                                            }}
                                            />
                                        {errors.email && <small style={{ color: 'red' }} className={"error"}>{errors.email.message}</small>}
    
                                    </div>
                                    <div className="mb-4 text-center">
                                        <button type="submit"
                                            className="btn btn-lg text-white col-12"
                                            style={{backgroundColor: buttonColorHexValue}}
                                            disabled={loading}
                                        >{t("Reset Password")}</button>
                                    </div>
                                </form>
                    </div>
                </div>
            </main>
        </div>
    </>
    );
}