import React from "react";
import AdminNav from "./AdminNav";
import IdleTimer from "react-idle-timer";
import NetworkUtil from "../../network/NetworkUtil";
import { AuthContext } from "../../context/AuthContext";
import AdminAPI from "../../network/AdminAPI";
import { getIdToken } from "firebase/auth";
import { sweetalert } from "../../App";

interface AdminPanelState {
    selectedPage?: string
    showLoading: boolean
    canRender
    isExpiredAlready
}

class AdminPanel extends React.Component<any, AdminPanelState> {

    idleTimer;
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            canRender: false,
            isExpiredAlready:false
        }
        this.idleTimer = null
        this.handleOnAction = this.handleOnAction.bind(this)
        this.handleOnActive = this.handleOnActive.bind(this)
        this.handleOnIdle = this.handleOnIdle.bind(this)
        this.checkAuth = this.checkAuth.bind(this);
    }

    async checkIsLoggedIn(){
        this.setState({showLoading: true})

        if(this.context){
            try{
                let idToken = await getIdToken(this.context);
                const userCredential = await AdminAPI.getAuthenticated({ token: idToken }); // lets try the accessToken 
                if(!userCredential){
                    this.setState({isExpiredAlready:true}, ()=>{
                        //@ts-ignore
                        document.getElementById("logoutForm").submit();
                    })
                }
            } catch(checkAuthError){
                this.setState({isExpiredAlready:true}, ()=>{
                    //@ts-ignore
                    document.getElementById("logoutForm").submit();
                });
            }
        }
    }

    async checkAuth(){
        this.setState({showLoading: true})
        if(!this.context){
            if(!this.state.isExpiredAlready){
                this.setState({isExpiredAlready:true}, () => {
                    // @ts-ignore
                    document.getElementById("logoutForm").submit();
                })
            }
        }
        if(this.context){
            try{
                let idToken = await getIdToken(this.context);
                const userCredential = await AdminAPI.getAuthenticated({ token: idToken }); 
                
                if(!userCredential){
                    this.setState({isExpiredAlready:true}, ()=>{
                        //@ts-ignore
                        document.getElementById("logoutForm").submit();
                    })
                }
            } catch(checkAuthError){
                console.error(checkAuthError)
                //error when checking UserContext for validToken
                this.setState({isExpiredAlready:true}, ()=>{
                    //@ts-ignore
                    // document.getElementById("logoutForm").submit();

                    window['location'] = '/login' as unknown as Location;
                });
            }
        }

    }

    handleOnAction (event) {}

    handleOnActive (event) {}

    handleOnIdle (event) {
        if(!this.state.isExpiredAlready) {
            return sweetalert.fire({
                icon:'warning',
                text:'You have been inactive for some time. You will be logged out in 30 seconds.',
                timer: 30000,
                showConfirmButton: true,
                confirmButtonText: 'Stay Logged In',
                allowOutsideClick: false,
            }).then((response)=>{
                if(response.isDismissed){
                    this.setState({isExpiredAlready:true}, () =>{
                        // @ts-ignore
                        document.getElementById("logoutForm").submit();
                    })
                }
            })
        }
    }

    componentDidMount() {
        this.checkIsLoggedIn();
        setInterval( this.checkAuth, 1000 * 60 * 15);
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined{

        return (
            <React.Fragment>
                <NetworkUtil />
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * 15}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={250}
                />
                <div id={"main"}>
                    <AdminNav onChange={(str => this.setState({selectedPage: str}))} {...this.props} />
                </div>

            </React.Fragment>
        )
    }
}

export default AdminPanel