import NetworkUtil from "./NetworkUtil";
import {Sample} from "../types/Sample";
import {ServerResponse} from "../types/ServerResponse";
import { BigQuerySample } from "../types/BigQuerySample";

export default class SamplesAPI {

    public static async getFilteredSamples(page, filter):Promise<{num_pages:number, totalSamples:number, table_data?:Sample[]}>{
        return NetworkUtil.makePost("/api/admin/sampleSearch?page=" + page, filter);
    }

    public static downloadFilesFromCloud(data):Promise<any>{
        return NetworkUtil.makePostForBlob("/api/admin/downloadFilesFromCloud", data);
    }

    public static async getFilteredSamplesForCheckin(page, filter):Promise<{num_pages:number, totalSamples:number, table_data?:Sample[]}>{
        return NetworkUtil.makePost("/api/admin/sampleSearchForCheckin?page=" + page, filter);
    }

    public static async getAllSamplesForAdminHome():Promise<{facilitySamplesArray}>{
        return NetworkUtil.makeGet("/api/admin/getAllSamplesForAdminHome")
    }

    public static async updateSample(sample, insuranceInformation, insuranceCards):Promise<ServerResponse>{
        let data = new FormData()
        data.set('insuranceInformation', JSON.stringify(insuranceInformation))
        data.set('patientInformation', JSON.stringify(sample))
        if(insuranceCards){
            for(let i = 0; i < insuranceCards.length; i++){
                let ext = insuranceCards[i].type.split('/').pop();
                data.append('insuranceCards', insuranceCards[i], `${i === 0 ? 'front' : 'back'}.${ext}`)
            }
        }
        return NetworkUtil.postForm("/api/admin/sampleEdit", data);
    }

    public static async updateResultsAccessedDate(id):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/sampleResultsAccessedDate", {ID: id});
    }

    public static editSample(editBody:SampleCheckinBody):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/sample/checkin", editBody);
    }

    public static getInsuranceCardImagesFromCloud(data):Promise<any>{
        return NetworkUtil.makePostForBlob("/api/admin/getInsuranceCardImagesFromCloud", data);
    }

    public static async resultsReviewedSendPatientEmail(id):Promise<ServerResponse>{
        return NetworkUtil.makePost("/api/admin/resultsReviewedSendPatientEmail", {ID: id});
    }

    public static async getSamplesByUID(user_id):Promise<{success:boolean,data:BigQuerySample[]}>{
        return NetworkUtil.makePost("/api/admin/getSamplesByUID", {user_id: user_id});
    }

}

export interface SampleCheckinBody{
    ID?
    PatientFirstName:string
    PatientLastName:string,
    PatientDOB
    ServiceID
    TestIDs
    SpecimenSourceID
    SpecimenID?
    Lot?
    HasLabTest?
    SpecimenIDsArray?
    LotsArray?
}