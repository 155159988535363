import React from "react";
import Overlay from "../Overlay";
import { AuthContext } from "../../context/AuthContext";
import { withTranslation } from "react-i18next";

interface AdminLandingPageState {
  showLoading?: boolean;
  facilitySamplesArray
  facilities
  batches
}

class AdminLandingPage extends React.Component<
  { t },
  AdminLandingPageState
> {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      facilitySamplesArray: [],
      facilities: [],
      batches: []
    };
  }

  componentDidMount(): void {
    this.setState({ showLoading: true }, async () => {
      this.setState({ showLoading: false });
    });
  }

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {

    return (

      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} />
        <div className="container-fluid">
          <div className={"row"}>
            <div className="col-12 pt-2">
              <main id="main-content" tabIndex={-1} aria-label="Admin Landing Page">
                <div className="row landing-card-deck">
                  <div className="col-6 col-md-3 p-2">
                    <a role="button" aria-label="Submit Vaccine Exemptions" className="d-flex flex-column justify-content-center align-items-center col-12 btn landingBtn immySubmitButtonSolid p-0" href={`/admin/VaccineExemptions`} style={{ backgroundColor: "#004e9a" }} >
                      <span className='pr-2 flex-grow-1 d-flex justify-content-center align-items-center'>
                        <img src={"https://storage.googleapis.com/streamline-bucket/injectionEnhanced.svg"} alt="" className='landingIcon' />
                      </span>
                      <span className="w-100 py-1" style={{ backgroundColor: "rgba(0,0,0,0.2)" }}>
                        {this.props.t("Submit Vaccine Exemptions")}
                      </span>
                    </a>
                  </div>
                  <div className="col-6 col-md-3 p-2">
                    <a role="button" aria-label="View Vaccine Exemptions" className="d-flex flex-column justify-content-center align-items-center col-12 btn landingBtn immySubmitButtonSolid p-0" href={`/admin/ExemptionRecords`} style={{ backgroundColor: "#004e9a" }} >
                      <span className='pr-2 flex-grow-1 d-flex justify-content-center align-items-center'>
                        <img src={"https://storage.googleapis.com/streamline-bucket/viewRecordEnhanced.svg"} alt="" className='landingIcon' />
                      </span>
                      <span className="w-100 py-1" style={{ backgroundColor: "rgba(0,0,0,0.2)" }}>
                        {this.props.t("View Vaccine Exemptions")}
                      </span>

                    </a>
                  </div>
                  <div className="col-6 col-md-3 p-2">
                    <a role="button" aria-label="Reset Password" className="d-flex flex-column justify-content-center align-items-center col-12 btn landingBtn immySubmitButtonSolid p-0" href={`/admin/PasswordReset`} style={{ backgroundColor: "#004e9a" }} >
                      <span className='pr-2 flex-grow-1 d-flex justify-content-center align-items-center'>
                        <img src={"https://storage.googleapis.com/streamline-bucket/reset-password.svg"} alt="" className='landingIcon' />
                      </span>
                      <span className="w-100 py-1" style={{ backgroundColor: "rgba(0,0,0,0.2)" }}>
                        {this.props.t("Reset Password")}
                      </span>
                    </a>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


export default withTranslation()(AdminLandingPage)