import React, { Component } from 'react'
import { sweetalert } from "../../App";
import Validator, { ValidationEntry } from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import Overlay from "../Overlay";
import { hideModal, buildRow, getLabel } from "../../util/FormatUtil";
import InBetweenOverlay from "../InBetweenOverlay";
import PhoneInput from 'react-phone-number-input';
import SystemAPI from '../../network/SystemAPI';
import { withTranslation } from 'react-i18next';
import AutoComplete from '../admin/Autocomplete';


interface PhysicianInfoModalState {
  PhysicianInfo?
  showLoading?
  showInBetween?
  relationships?
  imageBool?
}

interface PhysicianInfoModalProps {
  states?
  countries?
  gender?
  race?
  ethnicity?
  submit?
  upload?
  t
}

class PhysicianInfoModal extends Component<PhysicianInfoModalProps, PhysicianInfoModalState> {
  public static ID = "Physicianinfo";

  constructor(props) {
    super(props)
    this.state = {
      showLoading: false
    }
  }

  componentDidMount(): void {
    $(`#${PhysicianInfoModal.ID}`).on('shown.bs.modal', function() {
      $(document).off('focusin.modal');
  });
    SystemAPI.getAllRelationships().then(data => {
      this.setState({ relationships: data })
    })
  }

  validatePhysician(PhysicianInfo) {
    let patientFormValidation = {
      FirstName: PhysicianInfo?.PhysicianFirstName,
      LastName: PhysicianInfo?.PhysicianLastName,
      Address: PhysicianInfo?.PhysicianAddress,
      Email: PhysicianInfo?.PhysicianEmail,
      Phone: PhysicianInfo?.PhysicianPhone,
    };

    let validator = new Validator<any>()
      .withSimpleValidation("FirstName", Validators.requireNonNullValidator("First Name"))
      .withSimpleValidation("LastName", Validators.requireNonNullValidator("Last Name"))
      .withSimpleValidation("Address", Validators.requireNonNullValidator())
      .withComposedValidation("Email", new ValidationEntry(Validators.requireValidEmail("Email")), Validators.requireNotBlankAndLength(100, "Email"))
      .withComposedValidation("Phone", new ValidationEntry(Validators.requireNonNullValidator("Phone")), new ValidationEntry(Validators.requirePhone("Phone")))
    let validationResponse = validator.validate(patientFormValidation);
    if (!validationResponse.success) {
      return { success: false, error: validationResponse.error }
    } else {
      return { success: true }
    }
  }

  handleSave() {
    this.setState({ showLoading: true })

    let PhysicianInfoCopy = this.state.PhysicianInfo ? JSON.parse(JSON.stringify(this.state.PhysicianInfo)) : null
    if (PhysicianInfoCopy) {
      PhysicianInfoCopy.PhysicianFirstName = PhysicianInfoCopy && PhysicianInfoCopy.PhysicianFirstName ? PhysicianInfoCopy.PhysicianFirstName.trim() : null
      PhysicianInfoCopy.PhysicianLastName = PhysicianInfoCopy && PhysicianInfoCopy.PhysicianLastName ? PhysicianInfoCopy.PhysicianLastName.trim() : null
      PhysicianInfoCopy.PhysicianAddress = PhysicianInfoCopy.PhysicianAddress ? PhysicianInfoCopy.PhysicianAddress.trim() : null
      PhysicianInfoCopy.PhysicianAdditionalAddressInfo = PhysicianInfoCopy.PhysicianAdditionalAddressInfo ? PhysicianInfoCopy.PhysicianAdditionalAddressInfo.trim() : null
      PhysicianInfoCopy.PhysicianCity = PhysicianInfoCopy.PhysicianCity ? PhysicianInfoCopy.PhysicianCity.trim() : null
      PhysicianInfoCopy.PhysicianZip = PhysicianInfoCopy.PhysicianZip ? PhysicianInfoCopy.PhysicianZip.trim() : null
      PhysicianInfoCopy.PhysicianState = PhysicianInfoCopy.PhysicianState ? PhysicianInfoCopy.PhysicianState.trim() : null
      PhysicianInfoCopy.PhysicianEmail = PhysicianInfoCopy.PhysicianEmail ? PhysicianInfoCopy.PhysicianEmail.trim() : null
      PhysicianInfoCopy.PhysicianPhone = PhysicianInfoCopy.PhysicianPhone ? PhysicianInfoCopy.PhysicianPhone.trim() : null
    }

    this.setState({ PhysicianInfo: PhysicianInfoCopy })


    //validate patient info
    let res = this.validatePhysician(PhysicianInfoCopy)
    if (!res.success) {
      this.setState({ showLoading: false })
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: this.props.t(res.error),
      });
    } else {
      //pass back to Physician
      this.props.submit(PhysicianInfoCopy)

      hideModal(PhysicianInfoModal.ID)
      this.setState({ showLoading: false })
    }


  }

  render() {

    return (
      <React.Fragment>
                    <Overlay show_loading={this.state.showLoading} zIndex={100005}/>
                    <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100003} />
                    <div className="modal fade form_modal" id={PhysicianInfoModal.ID} tabIndex={-1} role="dialog"
                        aria-hidden="true">
                        <div className="modal-dialog modal-lg modal-xl col-8 px-0" role="document">
                            <div className="modal-content">
                                <div className="modal-body p-0 m-0">
                                    <div className="container-fluid">
                                        <div className={"row"}>
                                            <div className="col-12 pt-2">
                                                <div className="card mt-2">
                                                    <div className="card-header verlag-bold">
                                                        <div className="row">
                                                            <div className="col-10">
                                                                <h4>
                                                                    {this.props.t("Physician Information")}
                                                                </h4>
                                                            </div>
                                                            <div className="col-2">
                                                                <button style={{outline: 'none'}} type="button" className="close pr-4"
                                                                        aria-label={this.props.t("Close")} onClick={() => hideModal(PhysicianInfoModal.ID)}>
                                                                    <span aria-hidden="true" style={{fontSize: '1.5em', fontWeight: 'bold'}}>&times;</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        {buildRow(this.props.t('First Name'),
                                                          <input 
                                                              id={'PhysicianFirstName'}
                                                              className={"form-control"}
                                                              maxLength={50}
                                                              autoComplete={"off"}
                                                              type={"search"}
                                                              name={'PhysicianFirstName'}
                                                              aria-label={this.props.t("First Name Of Physician")}
                                                              onChange={(e)=>{
                                                                this.setState((prevState) => ({
                                                                  PhysicianInfo: {
                                                                      ...prevState.PhysicianInfo,
                                                                      PhysicianFirstName: e.target.value
                                                                  }
                                                              }))
                                                              }}
                                                              value={this.state.PhysicianInfo?.PhysicianFirstName}
                                                          />, this.props.t('First Name Of Physician'), true
                                                        )}
                                                        {buildRow(this.props.t('Last Name'),
                                                          <input 
                                                              id={'PhysicianLastName'}
                                                              className={"form-control"}
                                                              maxLength={50}
                                                              autoComplete={"off"}
                                                              type={"search"}
                                                              name={'PhysicianLastName'}
                                                              aria-label={this.props.t("Last Name Of Physician")}
                                                              onChange={(e)=>{
                                                                this.setState((prevState) => ({
                                                                  PhysicianInfo: {
                                                                      ...prevState.PhysicianInfo,
                                                                      PhysicianLastName: e.target.value
                                                                  }
                                                              }))
                                                              }}
                                                              value={this.state.PhysicianInfo?.PhysicianLastName}
                                                          />, this.props.t('Last Name Of Physician'), true
                                                        )}
                                                        
                                                        <div key={"PhysicianAutoComplete"} className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={this.props.t('Physician Address')}>
                                                          <label id={'Physician Address'} htmlFor={'Physicianautocomplete'} className="col-12 col-sm-4 col-form-label text-left px-3">{this.props.t('Physician Address')}<section className="text-danger d-inline-block px-1">*</section></label>
                                                          <div className="col-12 col-sm-8 p-0 m-0 text-center text-md-left" id={'Physician Address'}>
                                                            <AutoComplete key={"PhysicianAutocomplete"} id={"Physicianautocomplete"} onChange={(addr) => {this.setState((prevState) => ({PhysicianInfo: {...prevState.PhysicianInfo, PhysicianAddress: addr}}))}} updateFormData={(addr, city, state, zip, county, country) => {
                                                                this.setState((prevState) => ({PhysicianInfo: {...prevState.PhysicianInfo, PhysicianAddress: addr, PhysicianCity: city, PhysicianState: state, PhysicianZip: zip}}))
                                                            }}/>
                                                          </div>
                                                        </div>
                                                        {buildRow(this.props.t('Apartment/Suite/Building #'),
                                                          <input 
                                                              id={'SecondaryAddressLine'}
                                                              className={"form-control"}
                                                              maxLength={50}
                                                              autoComplete={"off"}
                                                              type={"search"}
                                                              name={'SecondaryAddressLine'}
                                                              aria-label={this.props.t("Apartment/Suite/Building #")}
                                                              onChange={(e)=>{
                                                                this.setState((prevState) => ({
                                                                  PhysicianInfo: {
                                                                      ...prevState.PhysicianInfo,
                                                                      PhysicianAdditionalAddressInfo: e.target.value
                                                                  }
                                                              }))
                                                              }}
                                                              value={this.state.PhysicianInfo?.PhysicianAdditionalAddressInfo}
                                                          />, this.props.t('Apartment/Suite/Building #')
                                                        )}
                                                        {buildRow(this.props.t('Office Phone'),
                                                            <PhoneInput
                                                                id={'PhysicianPhone'}
                                                                placeholder={this.props.t("Enter phone number")}
                                                                onChange={(e) => 
                                                                    this.setState( (prevState) => ({
                                                                    PhysicianInfo: {
                                                                        ...prevState.PhysicianInfo,
                                                                        PhysicianPhone: e
                                                                    }
                                                                }))}
                                                                defaultCountry="US"
                                                                aria-label={this.props.t("Cell Phone")}
                                                                value={this.state.PhysicianInfo?.PhysicianPhone}/>
                                                                , this.props.t('Cell Phone'), true
                                                        )}
                                                        <hr/>
                                                        <p className='text-center font-italic mb-4'>
                                                         {this.props.t("All Medical Exemptions require the approval of a licensed physician. If you already have a Medical Exemption Request approved by your physician, please upload it here. If you do not upload a physician approved medical exemption request form, you may opt to request their approval via email. Your exemption will remain pending until your physician has reviewed the emailed exemption request.")}
                                                        </p>
                                                        <p className='text-center my-3'>{this.props.t("Download the Medical Exemption Request form, ")}<a target='_blank' href='https://storage.googleapis.com/uploaded_document_bucket/development/OklahomaStateDepartmentofHealth/IMMY_MedicalExemptionRequest_Fillable.pdf'>{this.props.t("here")}</a></p>
                                                        <section className='col-12 p-0 d-flex flex-column flex-sm-row'>
                                                          <section className='d-flex flex-column flex-sm-row justify-content col-12 col-sm-6 mb-3 mb-sm-0'>
                                                            <label htmlFor="PhysicianApprovalLetter" className="col-12 col-sm-4 col-form-label text-center text-md-left px-0">{this.props.t("Medical Exemption Request Form")}</label>
                                                            <input 
                                                              className='col-12 col-sm-8 px-0'
                                                              type="file" 
                                                              id="PhysicianApprovalLetter" 
                                                              name="PhysicianApprovalLetter"
                                                              onChange={(e) => {
                                                                this.setState({imageBool: true})
                                                                this.props.upload(e.target.files[0])
                                                              }}
                                                            />
                                                          </section>
                                                          <section className='d-flex flex-row col-12 col-sm-6'>
                                                            <label htmlFor="PhysicianEmail" className="col-4 col-form-label text-center text-md-left px-0">{this.props.t("Physician Email")}<section className="text-danger d-inline-block px-1">*</section></label>
                                                            <input 
                                                              className='col-8 form-control'
                                                              type="search" 
                                                              id="PhysicianEmail" 
                                                              name="PhysicianEmail"
                                                              onChange={(e) => 
                                                                this.setState( (prevState) => ({
                                                                PhysicianInfo: {
                                                                    ...prevState.PhysicianInfo,
                                                                    PhysicianEmail: e.target.value
                                                                }
                                                                }))
                                                              }
                                                            />
                                                          </section>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer" style={{backgroundColor: 'transPhysician', borderTop: 'none'}}>
                                            <p className='d-inline-block'><section className="text-danger d-inline-block px-1">*</section> {this.props.t("Required Fields")}</p>
                                            <button className={"btn btn-primary float-right mb-3"} onClick={() => this.handleSave()}>{this.props.t("Save")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
    )
  }
}


export default withTranslation()(PhysicianInfoModal);