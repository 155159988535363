import React, { Component } from 'react'
import { sweetalert } from "../../App";
import Validator from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import Overlay from "../Overlay";
import { hideModal, buildRow, getLabel, getReactSelectAriaLabel, getReactSelectAriaLabelMultiselect, getLabelsForMultiSelect } from "../../util/FormatUtil";
import InBetweenOverlay from "../InBetweenOverlay";
import Select from "react-select";
import { withTranslation } from 'react-i18next';



interface ChildInfoModalState {
  childInfo?
  showLoading?
  showInBetween?
}

interface ChildInfoModalProps {
  states?
  countries?
  gender?
  race?
  ethnicity?
  submit?
  t
}

class ChildInfoModal extends Component<ChildInfoModalProps, ChildInfoModalState> {
  public static ID = "childinfo";

  constructor(props) {
    super(props)
    this.state = {
      showLoading: false,
      childInfo: {
        ChildBirthCountry: "",
        ChildBirthState: "",
        ChildDOB: null,
        ChildEthnicityID: null,
        ChildFirstName: "",
        ChildGenderID: null,
        ChildLastName: "",
        ChildMiddleInitial: "",
        ChildRaceID: []
      }
    }
  }

  componentDidMount(): void {
    $(`#${ChildInfoModal.ID}`).on('shown.bs.modal', function () {
      $(document).off('focusin.modal');
    });
  }

  validateChild(childInfo) {
    let childInfoValidation = {
      FirstName: childInfo?.ChildFirstName,
      MiddleInitial: childInfo?.ChildMiddleInitial,
      LastName: childInfo?.ChildLastName,
      MotherMaidenName: childInfo?.MotherMaidenName,
      DOB: childInfo?.ChildDOB,
      BirthCountry: childInfo?.ChildBirthCountry,
      BirthState: childInfo?.ChildBirthState,
      Gender: childInfo?.ChildGenderID ? childInfo?.ChildGenderID : null,
      Race: childInfo?.ChildRaceID.length ? childInfo?.ChildRaceID : null,
      Ethnicity: childInfo?.ChildEthnicityID ? childInfo?.ChildEthnicityID : null,
    };


    let validator = new Validator<any>()
      .withSimpleValidation("FirstName", Validators.requireNonNullValidator("First Name"))
      .withSimpleValidation("LastName", Validators.requireNonNullValidator("Last Name"))
      .withSimpleValidation("MotherMaidenName", Validators.requireNonNullValidator("Mother's Maiden Name"))
      .withValidation("DOB", Validators.requireSchoolAgeDOB("Date of Birth"))
      .withSimpleValidation("BirthCountry", Validators.requireNonNullValidator("Birth Country"))
      .withSimpleValidation("Gender", Validators.requireNonNullValidator("Gender"))
      .withSimpleValidation("Race", Validators.requireNonNullValidator("Race"))
      .withSimpleValidation("Ethnicity", Validators.requireNonNullValidator("Ethnicity"))
    if (childInfoValidation.BirthCountry === "United States") {
      validator = validator.withSimpleValidation("BirthState", Validators.requireNonNullValidator("Birth State"))
    }
    let validationResponse = validator.validate(childInfoValidation);
    if (!validationResponse.success) {
      return { success: false, error: validationResponse.error }
    } else {
      return { success: true }
    }
  }


  handleSave() {
    this.setState({ showLoading: true })
    let tempDate = this.state.childInfo ? this.state.childInfo.ChildDOB : null;
    let raceArray;
    let raceString;
    if (typeof this.state.childInfo?.ChildRaceID[0] === 'object') {
      raceArray = this.state.childInfo?.ChildRaceID?.map(obj => obj?.value);
      raceString = raceArray?.join(',')
    } else {
      raceArray = this.state.childInfo?.ChildRaceID
    }

    let childInfoCopy = this.state.childInfo ? JSON.parse(JSON.stringify(this.state.childInfo)) : null;

    this.setState({ childInfo: childInfoCopy })

    //validate child info
    let res = this.validateChild(childInfoCopy)
    if (!res.success) {
      this.setState({ showLoading: false })
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: this.props.t(res.error),
      });
    } else {

      if (childInfoCopy) {
        childInfoCopy.ChildFirstName = childInfoCopy && childInfoCopy.ChildFirstName ? childInfoCopy.ChildFirstName.trim() : null
        childInfoCopy.ChildMiddleInitial = childInfoCopy && childInfoCopy.ChildMiddleInitial ? childInfoCopy.ChildMiddleInitial.trim() : null
        childInfoCopy.ChildLastName = childInfoCopy && childInfoCopy.ChildLastName ? childInfoCopy.ChildLastName.trim() : null
        childInfoCopy.ChildDOB = tempDate ? tempDate : null
        childInfoCopy.ChildBirthCountry = childInfoCopy.ChildBirthCountry ? childInfoCopy.ChildBirthCountry.trim() : null
        childInfoCopy.ChildBirthState = childInfoCopy.ChildBirthState ? childInfoCopy.ChildBirthState.trim() : null
        childInfoCopy.ChildGenderID = childInfoCopy.ChildGenderID ? childInfoCopy.ChildGenderID : null
        childInfoCopy.ChildEthnicityID = childInfoCopy.ChildEthnicityID ? childInfoCopy.ChildEthnicityID : null
        childInfoCopy.ChildRaceID = childInfoCopy.ChildRaceID?.length > 0 ? raceArray : null
      }
      //pass back to parent
      this.props.submit(childInfoCopy)

      hideModal(ChildInfoModal.ID)
      this.setState({ showLoading: false })
    }

  }
  render() {

    let countriesCopy = this.props.countries && this.props.countries.length > 0 ? JSON.parse(JSON.stringify(this.props.countries)) : [];
    let indexOfUS = countriesCopy.findIndex(obj => obj.value === 'United States');
    let [removedObj] = countriesCopy.splice(indexOfUS, 1);
    countriesCopy.unshift(removedObj)

    let translationGenders = this.props.gender && this.props.gender.length > 0 ? this.props.gender.map(e => { return { label: this.props.t(e.label), value: e.value } }) : [];
    let translationEthnicities = this.props.ethnicity && this.props.ethnicity.length > 0 ? this.props.ethnicity.map(e => { return { label: this.props.t(e.label), value: e.value } }) : [];
    let translationRaces = this.props.race && this.props.race.length > 0 ? this.props.race.map(e => { return { label: this.props.t(e.label), value: e.value } }) : [];


    return (
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} zIndex={100005} />
        <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100003} />
        <div className="modal fade form_modal" id={ChildInfoModal.ID} tabIndex={-1} role="dialog"
          aria-hidden="true">
          <div className="modal-dialog modal-lg modal-xl col-8 px-0" role="document">
            <div className="modal-content">
              <div className="modal-body p-0 m-0">
                <div className="container-fluid">
                  <div className={"row"}>
                    <div className="col-12 pt-2">
                      <div className="card mt-2">
                        <div className="card-header verlag-bold">
                          <div className="row">
                            <div className="col-10">
                              <h4 className='m-0 mt-1'>
                                {this.props.t("Child Information")}
                              </h4>
                            </div>
                            <div className="col-2">
                              <button style={{ outline: 'none' }} type="button" className="close"
                                aria-label={this.props.t("Close")} onClick={() => hideModal(ChildInfoModal.ID)}>
                                <span aria-hidden="true" style={{ fontSize: '1.5em', fontWeight: 'bold' }}>&times;</span>
                              </button>
                            </div>
                            <span className={"text-center text-md-left text-danger pl-0 pl-sm-3 w-100"} style={{ fontSize: '1rem' }}>{this.props.t("An Exemption must be completed for each child")}</span>
                          </div>
                        </div>
                        <div className="card-body">
                          {buildRow(this.props.t('First Name'),
                            <input
                              id={'ChildFirstName'}
                              className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={'ChildFirstName'}
                              aria-label={this.props.t("First Name Of Child")}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  childInfo: {
                                    ...prevState.childInfo,
                                    ChildFirstName: e.target.value
                                  }
                                }))
                              }}
                              value={this.state.childInfo?.ChildFirstName}
                            />, this.props.t('First Name Of Child')
                            , true
                          )}
                          {buildRow(this.props.t('Middle Initial'),
                            <input
                              id={'ChildMiddleInitial'}
                              className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={'ChildMiddleInitial'}
                              aria-label={this.props.t("Middle Initial Of Child")}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  childInfo: {
                                    ...prevState.childInfo,
                                    ChildMiddleInitial: e.target.value
                                  }
                                }))
                              }}
                              value={this.state.childInfo?.ChildMiddleInitial}
                            />, this.props.t('Middle Initial Of Child')

                          )}
                          {buildRow(this.props.t('Last Name'),
                            <input
                              id={'ChildLastName'}
                              className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={'ChildLastName'}
                              aria-label={this.props.t("Last Name Of Child")}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  childInfo: {
                                    ...prevState.childInfo,
                                    ChildLastName: e.target.value
                                  }
                                }))
                              }}
                              value={this.state.childInfo?.ChildLastName}
                            />, this.props.t('Last Name Of Child')
                            , true
                          )}
                          {buildRow(this.props.t("Child's Mother's Maiden Name"),
                            <input
                              id={'MothersMaidenName'}
                              className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={'MothersMaidenName'}
                              aria-label={this.props.t("Mother's Maiden Name")}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  childInfo: {
                                    ...prevState.childInfo,
                                    MotherMaidenName: e.target.value
                                  }
                                }))
                              }}
                              value={this.state.childInfo?.MotherMaidenName}
                            />, this.props.t("Mother's Maiden Name")
                            , true
                          )}
                          {buildRow(this.props.t("Date of Birth"),
                            <input
                              id="ChildDateOfBirth"
                              min={'1000-01-01'}
                              max={new Date().toISOString().split('T')[0]}
                              className={"form-control"}
                              type={"date"}
                              name={"DOB"}
                              aria-label={this.props.t("Child Date of Birth")}
                              value={this.state.childInfo && this.state.childInfo.ChildDOB ? new Date(this.state.childInfo.ChildDOB).toISOString().split('T')[0] : ''}
                              onChange={(e) => {
                                if (Date.parse(e.target.value)) {
                                  this.setState((prevState) => ({
                                    childInfo: {
                                      ...prevState.childInfo,
                                      ChildDOB: new Date(e.target.value)
                                    }
                                  }))
                                }
                              }}
                            />, this.props.t("Child Date of Birth")
                            , true
                          )}
                          {buildRow(this.props.t('Birth Country'),
                            <Select
                              key={'ChildBirthCountry'}
                              id={'ChildBirthCountry'}
                              isSearchable={true}
                              placeholder={<div className="accessibilityText">{this.props.t("Please Select...")}</div>}
                              noOptionsMessage={() => this.props.t("No option")}
                              aria-label={getReactSelectAriaLabel("Birth Country",  getLabel(this.state.childInfo.ChildBirthCountry, this.props.countries, this.props.t), this.props.t)}
                              value={this.state.childInfo ? getLabel(this.state.childInfo.ChildBirthCountry, this.props.countries, this.props.t) : null}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  childInfo: {
                                    ...prevState.childInfo,
                                    ChildBirthCountry: e.value
                                  }
                                }))
                              }}
                              className={'state_select'}
                              options={countriesCopy}
                            />, this.props.t('Birth Country')
                            , true

                                                        )}
                                                        {buildRow(this.props.t('Birth State'),
                                                          <Select
                                                              key={'ChildBirthState'}
                                                              id={'ChildBirthState'}
                                                              isSearchable={true}
                                                              placeholder={<div className="accessibilityText">{this.props.t("Please Select...")}</div>}
                                                              noOptionsMessage={()=> this.props.t("No option")}
                                                              aria-label={this.props.t("Birth State")}
                                                              value={this.state.childInfo ? getLabel(this.state.childInfo.ChildBirthState, this.props.states, this.props.t) : null}
                                                              onChange={(e)=>{
                                                                this.setState((prevState) => ({
                                                                  childInfo: {
                                                                      ...prevState.childInfo,
                                                                      ChildBirthState: e.value
                                                                  }
                                                              }))
                                                              }}
                                                              className={'state_select'}
                                                              options={this.props.states}
                                                          />, this.props.t('Birth State')
                                                          , true
                                                      )}
                                                      {buildRow(this.props.t('Gender'),
                                                        <Select
                                                            key={'ChildGenderID'}
                                                            id={'ChildGenderID'}
                                                            isSearchable={true}
                                                            placeholder={<div className="accessibilityText">{this.props.t("Please Select...")}</div>}
                                                            noOptionsMessage={()=> this.props.t("No option")}
                                                            aria-label={this.props.t('Child Gender')}
                                                            value={this.state.childInfo ? getLabel(this.state.childInfo.ChildGenderID, this.props.gender, this.props.t) : null}
                                                            onChange={(e)=>{
                                                                this.setState( (prevState) => ({
                                                                    childInfo: {
                                                                        ...prevState.childInfo,
                                                                        ChildGenderID: e.value
                                                                    }
                                                                }))
                                                            }}
                                                            className={'state_select'}
                                                            options={translationGenders}
                                                        />, this.props.t('Child Gender')
                                                        , true
                                                      )}
                                                      {buildRow(this.props.t('Ethnicity'),
                                                        <Select
                                                            key={'ChildEthnicityID'}
                                                            id={'ChildEthnicityID'}
                                                            isSearchable={true}
                                                            placeholder={<div className="accessibilityText">{this.props.t("Please Select...")}</div>}
                                                            noOptionsMessage={()=> this.props.t("No option")}
                                                            aria-label={this.props.t('Child Ethnicity')}
                                                            value={this.state.childInfo ? getLabel(this.state.childInfo.ChildEthnicityID, this.props.ethnicity, this.props.t) : null}
                                                            onChange={(e)=>{
                                                                this.setState( (prevState) => ({
                                                                    childInfo: {
                                                                        ...prevState.childInfo,
                                                                        ChildEthnicityID: e.value
                                                                    }
                                                                }))
                                                            }}
                                                            className={'state_select'}
                                                            options={translationEthnicities}
                                                        />, this.props.t('Child Ethnicity')
                                                        , true
                                                      )}
                                                      {buildRow(this.props.t('Race (Limit 3)'),
                                                        <Select
                                                            key={'ChildRaceID'}
                                                            id={'ChildRaceID'}
                                                            isSearchable={true}
                                                            isMulti={true}
                                                            placeholder={<div className="accessibilityText">{this.props.t("Please Select...")}</div>}
                                                            isOptionDisabled={() => this.state.childInfo?.ChildRaceID?.length === 3}
                                                            noOptionsMessage={()=> this.props.t("No option")}
                                                            aria-label={this.props.t('Child Race')}
                                                            // value={this.state.childInfo ? getLabel(this.state.childInfo.ChildRaceID, this.props.race) : null}
                                                            onChange={(e)=>{
                                                                this.setState( (prevState) => ({
                                                                  childInfo: {
                                                                      ...prevState.childInfo,
                                                                      ChildRaceID: e ? e : []
                                                                  }
                                                                }))
                                                            }}
                                                            className={'state_select'}
                                                            options={translationRaces}
                                                        />, this.props.t('Child Race')
                                                        , true
                                                      )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer" style={{backgroundColor: 'transparent', borderTop: 'none'}}>
                                            <p className='d-inline-block'><section className="text-danger d-inline-block px-1">*</section> {this.props.t('Required Fields')}</p>
                                            <button className={"btn btn-primary float-right mb-3"} onClick={() => this.handleSave()}>{this.props.t("Save")}</button>
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
    )
  }
}

export default withTranslation()(ChildInfoModal)
