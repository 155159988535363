import * as React from "react";
import $ from "jquery"
import domain from "../../Domain";
import SidebarItems from "./SidebarItems";
import Overlay from "../Overlay";
import { sweetalert } from "../../App";
import { AuthContext } from "../../context/AuthContext"
import { UserContext } from "../../types/UserContext";
import AdminAPI from "../../network/AdminAPI";
import { getIdToken } from "firebase/auth";
import SystemAPI from "../../network/SystemAPI";
import { DefaultPasswordConfiguration, PasswordConfigurationValues } from "../../types/PasswordConfig";
// import { getBrandingImage } from "../../util/FormatUtil";
import LanguageSwitcher from "../LanguageSwitcher";
import { withTranslation } from "react-i18next";


interface AdminNavState {
    user?: UserContext
    tabs: string[]
    authPages: string[]
    banner,
    showLoading,
    showSidebar: boolean
    showProfile: boolean
    userInitial: string
    pswdConfigs?
    brandingImage: string
    adminNavBackgroundColorHexValue: string
    productName: string
    selectedLanguage
}

interface AdminProps {
    onChange: (str: string) => void
    history?
    t
}

export enum AdminPages {
    AppointmentForm = "Appointment Form",
    ProfileManagement = "Profile Management",
    VaccineRecords = "Vaccine Records",
    TreatmentRecords = "Treatment Records",
    FamilyGroup = "Family Group",
    Tests = "Tests",
    PasswordReset = "Password Reset",
    VaccineExemptionsForm = "Submit Vaccine Exemptions",
    ExemptionRecords = "View Vaccine Exemptions"
}

class AdminNav extends React.Component<AdminProps, AdminNavState> {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            tabs: Object.values(AdminPages),
            // user: {} as UserProfile,
            authPages: [],
            banner: "",
            showLoading: false,
            showSidebar: false,
            showProfile: false,
            userInitial: "",
            brandingImage: "",
            adminNavBackgroundColorHexValue: '',
            productName: "",
            selectedLanguage: 'en'
        }
        this.checkAuth = this.checkAuth.bind(this);
        this.handleShowSidebar = this.handleShowSidebar.bind(this);
        this.handleProfileMenu = this.handleProfileMenu.bind(this);
    }

    setupHooks() {
        $(document).ready(function () {


            $('#dismiss, .overlay').on('click', function () {
                // hide sidebar
                $('#sidebar').removeClass('active');
                // hide overlay
                $('.overlay').removeClass('active');
            });

            $('.navSelect').on('click', function () {
                // hide sidebar
                $('#sidebar').removeClass('active');
                // hide overlay
                $('.overlay').removeClass('active');
            });

            $('#sidebarCollapse').on('click', function () {
                // open sidebar
                if ($('#sidebar').hasClass('active')) {
                    $('#sidebar').removeClass('active');
                    // fade out the overlay
                    $('.overlay').removeClass('active');
                    $('.collapse.in').toggleClass('in');
                    $('a[aria-expanded=true]').attr('aria-expanded', 'true');
                } else {
                    $('#sidebar').addClass('active');
                    // fade in the overlay
                    $('.overlay').addClass('active');
                    $('.collapse.in').toggleClass('in');
                    $('a[aria-expanded=true]').attr('aria-expanded', 'false');
                }
            });

            $('#menu ul').hide();
            $('#menu ul').children('.current').parent().show();
            $('#menu li a').on('click', function () {
                let checkElement = $(this).next(); // looking for UL elements
                let clickedCaret = $(this).find(".nav-caret");

                if (
                    (checkElement.is('ul')) &&
                    (checkElement.is(':visible'))
                ) {
                    $('#menu ul:visible').slideDown('normal');
                    checkElement.slideUp('normal');

                    clickedCaret
                        .removeClass("fa-caret-up")
                        .addClass("fa-caret-down");

                    $('.nav-caret').not(clickedCaret).each(
                        function (index, el) {
                            if ($(el).hasClass('fa-caret-up')) {
                                $(el).removeClass('fa-caret-up')
                                $(el).addClass('fa-caret-down');
                            }
                        });
                    return false;
                }

                if (
                    (checkElement.is('ul')) &&
                    (!checkElement.is(':visible'))
                ) {
                    $('#menu ul:visible').slideUp('normal');
                    checkElement.slideDown('normal');

                    clickedCaret
                        .removeClass("fa-caret-down")
                        .addClass("fa-caret-up");

                    $('.nav-caret').not(clickedCaret).each(
                        function (index, el) {
                            if ($(el).hasClass('fa-caret-up')) {
                                $(el).removeClass('fa-caret-up')
                                $(el).addClass('fa-caret-down');
                            }
                        });
                    return false;
                }
            });
        });
    }

    async checkAuth() {
        if (!this.context) {
            sweetalert.fire({
                title: 'Error',
                text: 'You appear to be logged out. Please log in to access the application.',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then(() => {
                window['location'] = '/login' as unknown as Location
            });
            return
        }

        try {
            let idToken = await getIdToken(this.context);
            const userCredential = await AdminAPI.getAuthenticated({ token: idToken });

            if (userCredential) {
                await this.getPasswordConfig();

                let userProfile: UserContext = userCredential.user;
                // console.log(' !!! userProfile', userProfile)
                if (userProfile.Status === 'New' ||
                    userProfile.DefaultPassword !== null) {
                    sweetalert.fire({
                        icon: 'info',
                        text: 'Please reset your password.  ' + this.state.pswdConfigs.pattern.message
                    }).then(() => {
                        this.props.history.push({
                            pathname: `/__/auth/resetPassword?email=${userProfile.Email}`,
                        });
                    }).catch((e) => {
                        console.error(e)
                        return sweetalert.fire({ text: e.message, icon: 'error' });
                    })
                }
            }

            // console.log('userCredential', userCredential)

            let userProfile: UserContext = userCredential?.user;
            let userInitials = userProfile.FirstName[0] + userProfile.LastName[0];
            document.body.style.backgroundColor = 'white';
            this.setState({ userInitial: userInitials });
            this.setState({
                user: userCredential.user,
                authPages: userCredential.groups,
                userInitial: userInitials
            }, () =>
                this.setupHooks()
            )
        } catch (checkAuthError) {
            console.error(checkAuthError)

            sweetalert.fire({
                title: 'Error',
                text: 'Error verifying token. Please log in again.',
                icon: 'error',
                confirmButtonText: 'OK'
            }).then(() => {
                window['location'] = '/login' as unknown as Location;
            })
        }
    }

    async getPasswordConfig() {
        try {
            let settings = await SystemAPI.getPasswordRequirementConfig();
            if (!settings.success || !settings.data) {
                sweetalert.fire({ icon: 'warning', text: "Unable to get Password Requirement Configurations." })
            }
            let pswdConfigValues: PasswordConfigurationValues = settings?.data;
            let regex = await this.composeRegExp(pswdConfigValues);

            this.setState({
                pswdConfigs: {
                    required: DefaultPasswordConfiguration.required,
                    minLength: {
                        value: Number(pswdConfigValues.numChar),
                        message: `Password should contain at least ${pswdConfigValues.numChar} characters`,
                    },
                    pattern: {
                        value: new RegExp(regex.configString), // value must be a RegExp not a string
                        message: regex.configMessage
                    }
                }
            });
        } catch (e) {
            console.error(e)
            return sweetalert.fire({ text: e.message, icon: 'error' })
        }
    }

    async composeRegExp(pswdConfigValues) {
        let upper = pswdConfigValues.numUpper;
        let symbol = pswdConfigValues.numSymbols;
        let digits = pswdConfigValues.numDigits;
        let char = pswdConfigValues.numChar;
        // build regex string

        let startAnchor = `^`
        let newUpper = `(?=(.*[A-Z]){${upper}})`
        let newSymbol = `(?=(.*[!@#$%^&*]){${symbol}})`
        let newDigits = `(?=(.*[0-9]){${digits}})`
        let newChar = `.{${char},}`
        let endAnchor = `$`

        let regExpString = startAnchor + newUpper + newSymbol + newDigits + newChar + endAnchor;
        // console.log(" regExpString",regExpString);

        // build message 
        let message = `Password should contain at least ${char} characters `

        if ((upper && upper !== "0") ||
            (symbol && symbol !== "0") ||
            (digits && digits !== "0")
        ) {
            message += `and include `
        }

        if (upper && upper !== "0") { message += ` ${upper} uppercase (A-Z)` }
        if (symbol && symbol !== "0") { message += ` ${symbol} symbol (!@#$%^&*) ` }
        if (digits && digits !== "0") { message += ` ${digits} digits (0-9) ` }

        return {
            configMessage: message,
            configString: regExpString
        }
    }

    async componentDidMount() {
        this.checkAuth();
        let currentURL = window.location.href;
        this.setState({ showSidebar: false }, () => {

            SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
                let productID = data.ProductID;
                this.setState({ brandingImage: data.ProductAdminNavLogoURL, productName: data.Name, adminNavBackgroundColorHexValue: data.ProductAdminNavBackgroundColorHexValue });

                // console.log(" load banner ")
                SystemAPI.getBanner(productID).then(data => {
                    if (data.show) {
                        this.setState({ banner: data.banner, showLoading: false })
                    }
                    else {
                        this.setState({ showLoading: false })
                    }
                })

            })

        });
        window.addEventListener("click", (e) => {
            if (e.target !== document.getElementById('userMenu')) {
                this.setState({ showProfile: false })
            }
        });

    }

    getBannerElem() {
        if (!this.state.banner)
            return null;
        return (
            <div className="alert alert-danger m-0" role="alert">
                <p className="primary-black m-0">
                    {this.state.banner.Text}
                </p>
            </div>
        )
    }

    handleShowSidebar(e) {
        this.setState({ showSidebar: !this.state.showSidebar });
    }

    handleProfileMenu(e) {
        this.setState({ showProfile: !this.state.showProfile });
    }



    render() {

        // console.log('AdminNav state', this.state)

        let firstName = this.state.user && this.state.user.FirstName ? this.state.user.FirstName : "";
        let lastName = this.state.user && this.state.user.LastName ? this.state.user.LastName : "";
        let email = this.state.user && this.state.user.Email ? this.state.user.Email : "";
        let userRoles = this.state.user && this.state.user.UserRoles ? this.state.user.UserRoles.toString().replace(/,/g, ', ') : "";
        return (
            <React.Fragment>
                <div className="headerWrapper">
                    <Overlay show_loading={this.state.showLoading} />
                    {this.getBannerElem()}

                    <div className="container-fluid p-1" style={{ backgroundColor: this.state.adminNavBackgroundColorHexValue ? this.state.adminNavBackgroundColorHexValue : 'black' }}>
                        <div className="row justify-content-around justify-content-xl-between m-0">
                            <div className={"d-inline-block p-2 pt-1 pt-sm-3"}>
                                <div className={"row"}>
                                    <div className={"col-md-auto logoWrapper"}>
                                        <button type="button" className="btn btn-outline-dark float-left"
                                            aria-label="Sidebar Menu"
                                            id="sidebarCollapse"
                                            onClick={this.handleShowSidebar}>
                                            <span className="dark-blue-text" style={{ color: "#1e7fc1" }}><i className="fa fa-bars fa-1x" /></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center justify-content-md-between align-items-center flex-column flex-md-row col-8 col-md-10 col-xxl-11 pt-0 px-0">
                                <a className={!this.state.brandingImage || this.state.brandingImage.length < 1 ? 'd-none' : 'd-flex justify-content-center justify-content-md-start align-items-center mw-100 px-0'} href={"/admin"}>
                                    <img src={this.state.brandingImage}
                                        className={'admin-nav-logo'}
                                        alt={this.state.productName} />
                                </a>
                                <section aria-label="Language Selector" className="mw-50 py-1" style={{ minWidth: "120px" }}>
                                    <LanguageSwitcher onChange={(language) => this.setState({ selectedLanguage: language })} />
                                </section>
                            </div>
                            <div className={"d-inline-block p-2 pt-1 pt-sm-3"}>
                                <section>
                                    <button id="userMenu" onClick={this.handleProfileMenu} aria-label="Profile Menu" className={`${this.state.userInitial.charCodeAt(0) < 72 ? 'iconGreen' : this.state.userInitial.charCodeAt(0) >= 72 && this.state.userInitial.charCodeAt(0) < 79 ? 'iconBlue' : this.state.userInitial.charCodeAt(0) >= 79 && this.state.userInitial.charCodeAt(0) < 86 ? 'iconRed' : 'iconPurple'} userIcon float-right border-0`}>
                                        {this.state.userInitial}
                                    </button>
                                </section>
                            </div>
                            <nav
                                // ref={dropdownRef}
                                className={`menu ${this.state.showProfile ? "active" : "inactive"} ${this.state.banner ? 'menuWBanner' : 'menuWOBanner'}`}
                            >
                                <ul>
                                    <li>
                                        <div className="profileLogoWrapper">
                                            <div id="userMenuLarge"
                                                className={`${this.state.userInitial.charCodeAt(0) < 72 ? 'iconGreen' : this.state.userInitial.charCodeAt(0) >= 72 && this.state.userInitial.charCodeAt(0) < 79 ? 'iconBlue' : this.state.userInitial.charCodeAt(0) >= 79 && this.state.userInitial.charCodeAt(0) < 86 ? 'iconRed' : 'iconPurple'} userIconLarge float-right`}>
                                                {this.state.userInitial}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <p className=" profileMenuItem m-2">{firstName + " " + lastName}</p>
                                        <p className=" profileMenuItem m-2">{email}</p>
                                        {/* <p className=" profileMenuItem m-2">{userRoles}</p> */}
                                    </li>
                                    <li>
                                        <form method="post" action={'/clearSession'} id={"logoutForm"}>
                                            <button type="submit" onClick={() => { this.setState({ showLoading: true }) }} className="btn btn-outline-danger m-2 float-right">
                                                <span className="dard-blue-text">{this.props.t("Log Out")}</span>
                                            </button>
                                        </form>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                {/*This is my comment*/}
                <div className="overlay" />

                <nav id="sidebar" className={this.state.banner ? "sidebarTop1" : "sidebarTop2"} style={{ backgroundColor: this.state.adminNavBackgroundColorHexValue }}>
                    <div id={"sidebarWrapper p-0 m-0"}>
                        <div>
                            {/* <button type="button" className="btn btn-outline-light float-right" id="dismiss">
                                <i className="fa fa-arrow-left"/>
                            </button> */}

                            <SidebarItems
                                items={this.state.tabs}
                                authPages={this.state.authPages}
                                onChange={this.props.onChange}
                                backgroundColor={this.state.adminNavBackgroundColorHexValue}
                                showSidebar={this.state.showSidebar}
                            />
                        </div>

                    </div>
                </nav>

            </React.Fragment>
        );
    }
}

export default withTranslation()(AdminNav);