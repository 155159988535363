import React, { useEffect, useState} from 'react'
import { useForm } from 'react-hook-form';
import {sweetalert} from "../../App";
import UsersAPI from '../../network/UsersAPI';
import SystemAPI from '../../network/SystemAPI';
import InBetweenOverlay from '../InBetweenOverlay';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from "../LanguageSwitcher";

export default function ForgotPassword() {
    const [loading, setLoading] = useState(false)
    const [email, setEmail ] = useState(null)
    const [logoURL, setLogoURL] = useState('');
    const [branding, setBranding] = useState(null);
    const [buttonColorHexValue, setButtonColorHexValue] = useState('')
    const [backgroundColorHexValue, setBackgroundColorHexValue] = useState('')
    const [selectedLanguage, setSelectedLanguage] = useState('en')

    const { t } = useTranslation();

    // Form Validations
    const [form, setForm] = useState({})
    const { register, handleSubmit, formState: { errors }, trigger, reset} = useForm({
        mode: "onTouched"
    });
    const [isReset, setReset] = useState(false);
    
    useEffect(() => {
        console.log(' useEffect reset')
        reset(form);
        setReset(true);
    }, [reset, form]); // <= runs when reset state or form state updates

    useEffect(() => {
        console.log(' useEffect isReset')
        isReset && trigger()
    }, [isReset]) // <= runs when trigger state or isReset state updates

    useEffect(() => {
        console.log(' useEffect setForm')
        document.body.style.backgroundColor = 'black';

        setLoading(true)

        let currentURL = window.location.href;

        SystemAPI.getProductBrandingFromURL(currentURL).then(data => {
            let backgroundImageURL = data.ProductBackgroundImageURL;
            //@ts-ignore
            document.body.style.backgroundImage = `url(${backgroundImageURL}`;
            document.body.style.backgroundRepeat = "no-repeat";
            document.body.style.backgroundSize = "cover";
            
            setLogoURL(data.ProductLogoURL);
            setButtonColorHexValue(data.ProductButtonColorHexValue)
            setBackgroundColorHexValue(data.ProductAdminNavBackgroundColorHexValue)
            setBranding(data)
            setLoading(false)
        })
        
        setForm({
            email: null
        })
    }, []) //<= runs on first load

    const onSubmit = async (data) => {
        try {
            let response = await UsersAPI.sendResetPasswordEmail(null, email, branding); // this calls our backend route which will actuall 
            if(!response.success){
                return sweetalert.fire({icon:'error',text: response.reason})
            } else {
                sweetalert.fire({ icon: 'success', text: t('Please check your email for instructions.') })
                    .then(() => {
                        window['location'] = '/login' as unknown as Location;
                    })
            }
        }
        catch(e){
            console.error(e);
            return sweetalert.fire({text: e, icon: 'error'});
        }
        setLoading(false);
    }

    const onError = (e) => {
        console.error(e)
    }

    return (
        <>
        <InBetweenOverlay showInBetween={true} zIndex={-10}/>
        <div className={loading ? "d-none" : "container  min-vh-100"}>
            <div className="row justify-content-center mt-5">
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="card shadow">
                        <div className="card-title text-center border-bottom" style={{"backgroundColor": backgroundColorHexValue, "borderRadius":"3px"}}>
                            <a href="/">
                                <img src={logoURL}
                                    style={{ width: '200px', margin: '20px' }}
                                    className={'nav-logo'} alt={"logo"}
                                />
                            </a>
                        </div>
                        <div className="row text-center">
                            <div className="col-3"></div>
                            <div className="col-6">
                                <LanguageSwitcher onChange={(language) => setSelectedLanguage(language)}/>
                            </div>
                            <div className="col-3"></div>
                        </div>
                        <div className="card-body">

                            <form onSubmit={(e) => handleSubmit(onSubmit,onError)(e).catch((e)=>{
                                console.error(' catch ',e)
                            })}>
                                <div className="mb-4">
                                    <label htmlFor="email" className="form-label">{t("Username/Email")}</label>
                                    <input type="text" className="form-control" 
                                        id="email" name="email"
                                        {...register("email",{
                                            required:t("Username/Email is required"),
                                            pattern: {
                                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                                message: 'Please verify email syntax.'
                                            },
                                        })}
                                        onChange={(e)=>{
                                            setEmail(e.target.value.trim())
                                            setForm({...form, 'email':e.target.value.trim() })
                                        }}
                                        />
                                    {errors.email && <small style={{ color: 'red' }} className={"error"}>{errors.email.message}</small>}
 
                                </div>
                                <div className="mb-4 text-center">
                                    <button type="submit"
                                        className="btn btn-lg text-white col-12"
                                        style={{backgroundColor: buttonColorHexValue}}
                                        disabled={loading}
                                    >{t("Reset Password")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}