import React from "react";
import { withTranslation, Trans } from 'react-i18next';

class Footer extends React.Component<any, any>{
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (

            <footer className="container-fluid p-0 m-0 ">
                <hr className={"p-0 m-0"}/>
                    <div className="container-fluid">
                        <div className="row text-center text-dark">
                            <div className="col-1">
                                {/*<p>&copy; Copyright 2020 by IMMY. All Rights Reserved.</p>*/}
                            </div>
                            <div className="col-10">
                                <p>&copy;
                                    <Trans
                                        i18nKey="copyright" // optional -> fallbacks to defaults if not provided
                                        // defaults="Welcome to the <bold>{{productName}} Portal</bold>" // optional defaultValue
                                        values={{ currentYear: new Date().getFullYear()}}
                                        components={{ bold: <strong /> }}
                                    />
                                </p>
                            </div>
                            <div className="col-1">
                            </div>
                        </div>
                    </div>
            </footer>
        )
    }
}
export default withTranslation()(Footer);