import { getLabel, hideModal } from "../../util/FormatUtil";
import React from "react";
import moment from "moment";
import ReactToPrint from "react-to-print";
import SystemAPI from "../../network/SystemAPI";

interface CertificateProps {
  data?
  branding?
  immunizations?
  genders?
  races?
  exemptImmunizations?
}
interface CertificateState {
  exemptImmunizations?
  immunizations?
  exemptionTypes?
  allImmunizationsExempt?
  schools?
  childcares?
  schoolGrade?
  exemptionReasons?
}

export default class VaccineExemptionCertificate extends React.Component<CertificateProps, CertificateState> {
  public static ID = "ExemptionCertificate";
  private componentRef: any;

  constructor(props) {
    super(props);
    this.state = {}
    this.getSchoolList = this.getSchoolList.bind(this);
  }

  componentDidMount(): void {
    document.body.style.backgroundImage = ``;
    document.body.style.backgroundColor = '#FFFFFF'

    SystemAPI.getAllImmunizations().then(data => {
      this.setState({ immunizations: data })
    })
    SystemAPI.getAllExemptionTypes().then(data => {
      this.setState({ exemptionTypes: data })
    })
    SystemAPI.getAllSchools().then(data => {
      //@ts-ignore
      this.setState({ schools: data.schools.map((school) => { return { value: school.ID, label: school.SchoolName }})})
    })
    SystemAPI.getAllChildCareFacilities().then(data => {
      //@ts-ignore
      this.setState({ childcares: data.facilities.map((facility) => {return {label: facility.ChildCareSiteName, value: facility.ID }})})
    })
    SystemAPI.getAllPersonalExemptionReasons().then(data => {
      this.setState({ exemptionReasons: data })
    })
  }


  componentWillReceiveProps(nextProps: Readonly<CertificateProps>, nextContext: any): void {
    let schoolGrade = nextProps.data ? this.getSchoolGrade(nextProps.data?.EducationSite) : null;
    this.setState({ schoolGrade: schoolGrade })
  }

  buildResultDescription(data) {
    let foo;
    foo = data.Result.split(';').map((d) => {
      let isPositive = false;
      if (d.includes('Positive')) { isPositive = true; }
      return (
        <div>
          <p className={(data.HighlightResult && isPositive ? data.HighlightColor : null)}>{d}</p>
          {/* <p className={(data.HighlightResult && isPositive ? data.HighlightColor : null)}>*****</p> */}
        </div>
      )
    })
    return foo;
  }

  getRacesList(data) {
    if (data?.ChildRaceID?.length < 2) {
      return getLabel(parseInt(data?.ChildRaceID), this.props.races).label
    } else {
      let raceArray = data?.ChildRaceID?.split(',')
      let raceStringArray = [];
      for (let val of raceArray) {
        raceStringArray.push(getLabel(parseInt(val), this.props.races).label);
      }
      let raceString = raceStringArray.join(", ");
      return raceString;
    }
  }

  getContraindicatedImmunizationsList(data) {
    if (data?.ContraIndicatedImmunizations?.length < 2) {
      return getLabel(parseInt(data?.ContraIndicatedImmunizations), this.props.immunizations).label
    } else if (data?.ContraIndicatedImmunizations?.length > 0) {
      let immunizationArray = data?.ContraIndicatedImmunizations?.split(',')
      let immunizationStringArray = [];
      for (let val of immunizationArray) {
        immunizationStringArray.push(getLabel(parseInt(val), this.props.immunizations).label);
      }
      let immunizationString = immunizationStringArray.join(", ");
      return immunizationString;
    } else {
      return
    }
  }

  getExemptionType(data) {
    if (data?.ExemptionType === 5) {
      return "Medical Exemption"
    } else if (data?.ExemptionType === 2) {
      return "Religious Objection"
    } else if (data?.ExemptionType === 3) {
      return "Personal Objection"
    } else {
      return null
    }
  }

  areAllImmunizationsExempt() {
    let idArray = this.state.immunizations?.map(obj => obj.value);

    let success = idArray?.every((val => this.props.exemptImmunizations?.includes(val)))
    if (success) {
      this.setState({ allImmunizationsExempt: true })
    }
    return success
  }

  getSchoolList(data) {
    let parsedData = JSON.parse(data);
    if (parsedData?.length < 2) {
      let schoollabel = parsedData[0].OtherSchool ? parsedData[0].OtherSchool : getLabel(parseInt(parsedData.map(d => d?.School)), this.state.schools)?.label
      let childCareLabel = parsedData[0].OtherChildCareSite ? parsedData[0].OtherChildCareSite: getLabel(parseInt(parsedData.map(d => d?.ChildCareSite)), this.state.childcares)?.label

      return schoollabel ? schoollabel : childCareLabel ? childCareLabel : ""

    } else if (parsedData?.length >= 2) {
      let schoolArray = parsedData?.map((obj) => { return obj.OtherSchool ?  obj.OtherSchool : obj.School }).filter((elem) => { return elem != null })
      let schoolStringArray = [];
      for (let val of schoolArray) {
        schoolStringArray.push(typeof val === 'number' ? getLabel(val, this.state.schools)?.label : val);
      }

      let schoolString;
      if (schoolStringArray.length > 1) {
        schoolString = schoolStringArray.join(", ");
      } else {
        schoolString = schoolStringArray[0];
      }

      let childCareArray = parsedData?.map((obj) => { return obj.OtherChildCareSite ? obj.OtherChildCareSite : obj.ChildCareSite }).filter((elem) => { return elem != null })
      let childCareStringArray = [];
      for (let val of childCareArray) {
        childCareStringArray.push(typeof val === 'number' ? getLabel(val, this.state.childcares)?.label : val);
      }

      let childCareString;
      if (childCareStringArray.length > 1) {
        childCareString = childCareStringArray.join(", ");
      } else {
        childCareString = childCareStringArray[0];
      }


      if (schoolString && childCareString) {
        return schoolString + ", " + childCareString;
      } else if (schoolString) {
        return schoolString;
      } else if (childCareString) {
        return childCareString;
      } else {
        return ""
      }

    } else {
      return
    }
  }

  getSchoolGrade(data) {
    let parsedData = JSON.parse(data);
    if (parsedData?.length < 2) {
      if (parsedData[0].SchoolGrade) {
        return parsedData.map(d => d?.SchoolGrade)
      } else {
        return null
      }
    } else if (parsedData?.length >= 2) {
      let gradeArray = parsedData?.map((obj) => { return obj.SchoolGrade }).filter((elem) => { return elem != null })
      let gradeStringArray = [];
      for (let val of gradeArray) {
        gradeStringArray.push(val);
      }

      let gradeString;
      if (gradeStringArray.length > 1) {
        gradeString = gradeStringArray.join(", ");
      } else {
        gradeString = gradeStringArray[0];
      }

      return gradeString;

    } else {
      return
    }
  }

  render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

    let data = this.props.data;
    let areAllImmunizationsExempt = () => {
      let idArray = this.state.immunizations?.map(obj => obj.value);

      let success = idArray?.every((val => this.props.exemptImmunizations?.includes(val)))

      return success
    }


    return (
      <>
        <div
          className="modal fade form_modal"
          id={VaccineExemptionCertificate.ID}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="result_modal_label"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-xl col-8 px-0"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body p-0 m-0">
                <div className="container-fluid">
                  <div className={"row"}>
                    <div className="col-12 pt-2">
                      <div className="card mt-2">
                        <div className="card-header verlag-bold">
                          <div className="row">
                            <div className="col-10">
                              <h4 className="d-inline-block">Certificate</h4>
                              <div className="d-none d-md-inline-block">
                                <ReactToPrint
                                  trigger={() => (
                                    <button
                                      type={'button'}
                                      className={'btn btn-outline-success ml-3'}
                                      onClick={() => { }}
                                    >
                                      {"Print To PDF"}
                                    </button>
                                  )}
                                  content={() => this.componentRef}
                                />
                              </div>
                            </div>
                            <div className="col-2">
                              <button
                                style={{ outline: "none" }}
                                type="button"
                                className="close pr-4"
                                aria-label="Close"
                                onClick={() =>
                                  hideModal(
                                    VaccineExemptionCertificate.ID
                                  )
                                }
                              >
                                <span
                                  aria-hidden="true"
                                  style={{
                                    fontSize: "1.5em",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &times;
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="row min-vh-100 mb-5 " style={{ fontSize: "18px" }} ref={el => (this.componentRef = el)}>
                          <header className="col-10 text-center verlag-bold py-1 px-0 mx-auto mb-3" style={{ borderBottom: "1px solid black" }}>
                            <img
                              src={this.props.branding?.ProductLogoURL}
                              height={120}
                              className="d-inline-block align-top nav-href pr-2"
                              alt=""
                            />

                          </header>
                          <div className="col-10 mx-auto pt-2 pb-2 responsive-p-sm px-0 smallPadding" style={{ borderBottom: "1px solid black" }}>
                            <p className="mb-4">{moment().utc().format("MM/DD/YY")}</p>
                            <p className="mb-3">To Whom It May Concern</p>
                            <p className="mb-3">
                              The Oklahoma State Department of Health, Immunization Service has received your request for
                              exemption of vaccine(s).
                            </p>
                            <p className="mb-3">
                              Your request for exemption has been <strong style={{textDecoration: 'underline'}}>{data?.Status ? "approved" : "denied"}</strong> for the below named child.
                            </p>
                            <p className="mb-3">
                              Please note that Exemptions expire after 6th grade and a new exemption request will need to be submitted before enrollment into 7th grade.
                            </p>
                            <p className="mb-3">
                              For more information regarding immunizations and exemptions please see the following statutes and administrative rules: Child Care Facilities - 10 O.S. §§ 411 to 414; K through 12th grade - 70 O.S. §§ 1210.191 to 1210.193; and Oklahoma Administrative Code Title 310, Chapter 535. Immunization Regulations, Subchapter 1. Childhood Immunizations.
                            </p>
                          </div>
                          <div className="col-10 mx-auto pt-2 pb-2 responsive-p-sm px-0" style={{ borderBottom: "1px solid black" }}>
                            <section className="d-flex justify-content-between">
                              <p>Name of Child: <p className="d-inline-block m-0" style={{ color: "blue" }}>{data?.ChildFirstName + " " + data?.ChildLastName}</p></p>
                              <p>Birthdate of Child: <p className="d-inline-block m-0" style={{ color: "blue" }}>{moment(data?.ChildDOB).utc().format("MM/DD/YY")}</p></p>
                            </section>
                            <section className="">
                              <p>Name of Parent/Guardian Requesting: <p className="d-inline-block m-0" style={{ color: "blue" }}>{data?.ParentFirstName + " " + data?.ParentLastName}</p></p>
                              <p>Name of School, Child Care Facility or Head Start: <p className="d-inline-block m-0" style={{ color: "blue" }}>{data && data?.EducationSite && this.getSchoolList(data?.EducationSite)}</p></p>
                              {this.state.schoolGrade ? <p>School Grade: <p className="d-inline-block m-0" style={{ color: "blue" }}>{data && data?.EducationSite && this.state.schoolGrade}</p></p> : <> </>}
                              <p>Date/Time Requested: <p className="d-inline-block m-0" style={{ color: "blue" }}>{moment(data?.CreatedDate).format("MM/DD/YY hh:mm a")}</p></p>
                              <p>Date/Time {data?.Status ? "Approved" : "Denied"}: <p className="d-inline-block m-0" style={{ color: "blue" }}>{data?.Status ? moment(data?.ApprovalDate).format("MM/DD/YY hh:mm a") : moment(data?.DenialDate).format("MM/DD/YY hh:mm a")}</p></p>
                            </section>
                            <p>Exemption Type: <p className="d-inline-block m-0" style={{ color: "blue" }}> {getLabel(data?.ExemptionType, this.state.exemptionTypes)?.label}</p></p>
                            {data?.Status ? <>
                              {data?.ExemptionType === 1 ? <p>Reason: <p className="d-inline-block m-0" style={{ color: "blue" }}> {getLabel(data?.PersonalExemptionReason, this.state.exemptionReasons)?.label}</p></p> : <></>}
                              <section>
                                <p>Exempt from the following checked vaccines:</p>
                                <section className="d-flex flex-row justify-content-between" style={{ fontSize: '18px' }}>
                                  <div className="column">
                                    <div className="form-check">
                                      <input className="form-check-input" readOnly={true} style={{ width: "20px", height: "20px" }} type="checkbox" value="" id="flexCheckCheckedAll" checked={areAllImmunizationsExempt()} />
                                      <label className="form-check-label ml-2" htmlFor="flexCheckCheckedAll">
                                        ALL
                                      </label>
                                    </div>
                                    {
                                      this.state.immunizations?.slice(0, Math.ceil(this.state.immunizations.length / 2)).map((immun) => {
                                        return (
                                          <div className="form-check">
                                            <input className="form-check-input" readOnly={true} style={{ width: "20px", height: "20px" }} type="checkbox" value="" id={`flexCheckChecked${(immun.label.replace(" ", ""))}`} checked={areAllImmunizationsExempt() ? false : this.props.exemptImmunizations?.includes(immun.value)} />
                                            <label className="form-check-label ml-2" htmlFor={`flexCheckChecked${(immun.label.replace(" ", ""))}`}>
                                              {immun.label}
                                            </label>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                  <div className="column">
                                    {
                                      this.state.immunizations?.slice(Math.ceil(this.state.immunizations.length / 2)).map((immun) => {
                                        return (
                                          <div className="form-check">
                                            <input className="form-check-input" readOnly={true} style={{ width: "20px", height: "20px" }} type="checkbox" value="" id={`flexCheckChecked${(immun.label.replace(" ", ""))}`} checked={areAllImmunizationsExempt() ? false : this.props.exemptImmunizations?.includes(immun.value)} />
                                            <label className="form-check-label ml-2" htmlFor={`flexCheckChecked${(immun.label.replace(" ", ""))}`}>
                                              {immun.label}
                                            </label>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                </section>
                              </section></> : <></>}
                              {data?.ExemptionType === 5 && !data?.Status ? <p className="m-0">Physician Reason for Denial: <p className="d-inline-block m-0" style={{ color: "blue" }}>{data?.PhysicianDenialReason}</p></p> : <></>}
                          </div>
                          <div className="col-10 mx-auto pt-2 pb-2 responsive-p-sm px-0">
                            <p className="mb-3">Please feel free to reach out if you have any questions.</p>
                            <section className="d-flex flex-column flex-sm-row justify-content-between mb-2">
                              <p className="m-0">
                                <strong>Immunization Exemption Mailbox:</strong><br />
                                IMM.Exemption@health.ok.gov
                              </p>
                              <p className="m-0">
                                <strong>Immunization Service Help Desk:</strong><br />
                                (405) 426-8580
                              </p>
                            </section>
                          </div>
                          <footer className="col-10 mx-auto pt-2 pb-2 responsive-p-sm px-0 text-center" style={{ borderTop: "1px solid black" }}>
                            <p className="m-0">Oklahoma State Department of Health</p>
                            <p>(405) 426-8000 • (800) 522-0203 • Oklahoma.gov/Health</p>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}