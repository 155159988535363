import React, { Component } from 'react'
import { sweetalert } from "../../App";
import Validator, { ValidationEntry } from "../../validation/Validator";
import { Validators } from "../../validation/Validators";
import Overlay from "../Overlay";
import { hideModal, buildRow, getLabel, getReactSelectAriaLabel } from "../../util/FormatUtil";
import InBetweenOverlay from "../InBetweenOverlay";
import Select from "react-select";
import PhoneInput from 'react-phone-number-input';
import SystemAPI from '../../network/SystemAPI';
import { withTranslation } from 'react-i18next';
import AutoComplete from '../admin/Autocomplete';
import { AuthContext } from '../../context/AuthContext';
import PatientAPI from '../../network/PatientAPI';


interface ParentInfoModalState {
  parentInfo?
  showLoading?
  showInBetween?
  relationships?
}

interface ParentInfoModalProps {
  states?
  countries?
  gender?
  race?
  ethnicity?
  submit?
  t
}

class ParentInfoModal extends Component<ParentInfoModalProps, ParentInfoModalState> {
  public static ID = "parentinfo";
  static contextType = AuthContext;

  constructor(props) {
    super(props)
    this.state = {
      showLoading: false
    }
  }

  componentDidMount(): void {
    $(`#${ParentInfoModal.ID}`).on('shown.bs.modal', function () {
      $(document).off('focusin.modal');
    });
    //@ts-ignore
    SystemAPI.getAllRelationships().then(data => {
      this.setState({ relationships: data })
    })
    PatientAPI.getUserProfileByUID(this.context.uid).then(response => {
      let profileData = response.data[0];
      this.setState({
        parentInfo: {
          ParentFirstName: profileData && profileData.firstName ? profileData.firstName : null,
          ParentMiddleInitial: profileData && profileData.middleName ? profileData.middleName : null,
          ParentLastName: profileData && profileData.lastName ? profileData.lastName : null,
          ParentEmail: profileData && profileData.email ? profileData.email : this.context.email,
          ParentPhone: profileData && profileData.phoneNumber ? profileData.phoneNumber : null,
          ParentAddress: profileData && profileData.streetAddress ? profileData.streetAddress : null,
          ParentCity: profileData && profileData.city ? profileData.city : null,
          ParentState: profileData && profileData.state ? profileData.state : null,
          ParentZip: profileData && profileData.zipcode ? profileData.zipcode : null,
          ParentCounty: profileData && profileData.country ? profileData.county : null,
          textOptOut: profileData && profileData.TextOptOut ? profileData.TextOptOut : false,
          RelationshipID: null // not stored in our PatientProfile table
        },
        showLoading: false
      }); // set state profile
    })
  }

  validateParent(parentInfo) {
    let patientFormValidation = {
      FirstName: parentInfo?.ParentFirstName,
      MiddleInitial: parentInfo?.ParentMiddleInitial,
      LastName: parentInfo?.ParentLastName,
      Address: parentInfo?.ParentAddress,
      Email: parentInfo?.ParentEmail,
      Phone: parentInfo?.ParentPhone,
      Relationship: parentInfo?.RelationshipID
    };

    let validator = new Validator<any>()
      .withSimpleValidation("FirstName", Validators.requireNonNullValidator("First Name"))
      .withSimpleValidation("LastName", Validators.requireNonNullValidator("Last Name"))
      .withSimpleValidation("Relationship", Validators.requireNonNullValidator("Relationship"))
      .withSimpleValidation("Address", Validators.requireNonNullValidator())
      .withComposedValidation("Email", new ValidationEntry(Validators.requireValidEmail("Email")), Validators.requireNotBlankAndLength(100, "Email"))
      .withComposedValidation("Phone", new ValidationEntry(Validators.requireNonNullValidator("Phone")), new ValidationEntry(Validators.requirePhone("Phone")))
    let validationResponse = validator.validate(patientFormValidation);
    if (!validationResponse.success) {
      return { success: false, error: validationResponse.error }
    } else {
      return { success: true }
    }
  }

  handleSave() {
    this.setState({ showLoading: true })

    let tempDate = this.state.parentInfo ? this.state.parentInfo.ParentDOB : null;

    let parentInfoCopy = this.state.parentInfo ? JSON.parse(JSON.stringify(this.state.parentInfo)) : null
    if (parentInfoCopy) {
      parentInfoCopy.ParentFirstName = parentInfoCopy && parentInfoCopy.ParentFirstName ? parentInfoCopy.ParentFirstName.trim() : null
      parentInfoCopy.ParentMiddleInitial = parentInfoCopy && parentInfoCopy.ParentMiddleInitial ? parentInfoCopy.ParentMiddleInitial.trim() : null
      parentInfoCopy.ParentLastName = parentInfoCopy && parentInfoCopy.ParentLastName ? parentInfoCopy.ParentLastName.trim() : null
      parentInfoCopy.ParentAddress = parentInfoCopy.ParentAddress ? parentInfoCopy.ParentAddress.trim() : null
      parentInfoCopy.ParentAdditionalAddressInfo = parentInfoCopy.ParentAdditionalAddressInfo ? parentInfoCopy.ParentAdditionalAddressInfo.trim() : null
      parentInfoCopy.ParentCity = parentInfoCopy.ParentCity ? parentInfoCopy.ParentCity.trim() : null
      parentInfoCopy.ParentZip = parentInfoCopy.ParentZip ? parentInfoCopy.ParentZip.trim() : null
      parentInfoCopy.ParentCounty = parentInfoCopy.ParentCounty ? parentInfoCopy.ParentCounty.trim() : null
      parentInfoCopy.ParentState = parentInfoCopy.ParentState ? parentInfoCopy.ParentState.trim() : null
      parentInfoCopy.ParentEmail = parentInfoCopy.ParentEmail ? parentInfoCopy.ParentEmail.trim() : null
      parentInfoCopy.ParentPhone = parentInfoCopy.ParentPhone ? parentInfoCopy.ParentPhone.trim() : null
    }

    this.setState({ parentInfo: parentInfoCopy })

    //validate patient info
    let res = this.validateParent(parentInfoCopy)
    if (!res.success) {
      this.setState({ showLoading: false })
      return sweetalert.fire({
        icon: "error",
        title: "",
        text: this.props.t(res.error),
      });
    } else {
      //pass back to parent
      this.props.submit(parentInfoCopy)

      hideModal(ParentInfoModal.ID)
      this.setState({ showLoading: false })
    }


  }
  render() {

    let translationRelationships = this.state.relationships && this.state.relationships.length > 0 ? this.state.relationships.map(e => { return { label: this.props.t(e.label), value: e.value } }) : [];
    return (
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} zIndex={100005} />
        <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100003} />
        <div className="modal fade form_modal" id={ParentInfoModal.ID} tabIndex={-1} role="dialog"
          aria-hidden="true">
          <div className="modal-dialog modal-lg modal-xl col-8 px-0" role="document">
            <div className="modal-content">
              <div className="modal-body p-0 m-0">
                <div className="container-fluid">
                  <div className={"row"}>
                    <div className="col-12 pt-2">
                      <div className="card mt-2">
                        <div className="card-header verlag-bold">
                          <div className="row">
                            <div className="col-10">
                              <h4>
                                {this.props.t("Parent/ Guardian Information")}
                              </h4>
                            </div>
                            <div className="col-2">
                              <button style={{ outline: 'none' }} type="button" className="close"
                                aria-label={this.props.t("Close")} onClick={() => hideModal(ParentInfoModal.ID)}>
                                <span aria-hidden="true" style={{ fontSize: '1.5em', fontWeight: 'bold' }}>&times;</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          {buildRow(this.props.t('First Name'),
                            <input
                              id={'ParentFirstName'}
                              className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={'ParentFirstName'}
                              aria-label={this.props.t("First Name Of Parent")}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  parentInfo: {
                                    ...prevState.parentInfo,
                                    ParentFirstName: e.target.value
                                  }
                                }))
                              }}
                              value={this.state.parentInfo?.ParentFirstName}
                            />, this.props.t('First Name Of Parent')
                            , true
                          )}
                          {buildRow(this.props.t('Middle Initial'),
                            <input
                              id={'ParentMiddleInitial'}
                              className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={'ParentMiddleInitial'}
                              aria-label={this.props.t("Middle Initial Of Parent")}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  parentInfo: {
                                    ...prevState.parentInfo,
                                    ParentMiddleInitial: e.target.value
                                  }
                                }))
                              }}
                              value={this.state.parentInfo?.ParentMiddleInitial}
                            />, this.props.t('Middle Initial Of Parent')
                          )}
                          {buildRow(this.props.t('Last Name'),
                            <input
                              id={'ParentLastName'}
                              className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={'ParentLastName'}
                              aria-label={this.props.t("Last Name Of Parent")}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  parentInfo: {
                                    ...prevState.parentInfo,
                                    ParentLastName: e.target.value
                                  }
                                }))
                              }}
                              value={this.state.parentInfo?.ParentLastName}
                            />, this.props.t('Last Name Of Parent')
                            , true
                          )}
                          {buildRow(this.props.t('Relationship'),
                            <Select
                              key={'Relationship'}
                              id={'Relationship'}
                              isSearchable={true}
                              placeholder={<div className="accessibilityText">{this.props.t("Please Select...")}</div>}
                              noOptionsMessage={() => this.props.t("No option")}
                              aria-label={getReactSelectAriaLabel("Relationship", this.state.parentInfo ? getLabel(this.state.parentInfo.RelationshipID, this.state.relationships, this.props.t) : null, this.props.t)}
                              value={this.state.parentInfo ? getLabel(this.state.parentInfo.RelationshipID, this.state.relationships, this.props.t) : null}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  parentInfo: {
                                    ...prevState.parentInfo,
                                    RelationshipID: e.value
                                  }
                                }))
                              }}
                              className={'state_select'}
                              options={translationRelationships}
                            />, this.props.t('Relationship to Child')
                            , true
                          )}
                          <div key={"ParentAutoComplete"} className="form-group row" data-toggle={'tooltip'} data-placement={'top'} title={this.props.t('Address')}>
                            <label id={'Address'} htmlFor={'Parentautocomplete'} className="col-12 col-sm-4 col-form-label px-3">{this.props.t('Address')}<section className="text-danger d-inline-block px-1">*</section></label>
                            <div className="col-12 col-sm-8 p-0 m-0 text-center text-md-left" id={'Address'}>
                              <AutoComplete key={"Parentautocomplete"} id={"Parentautocomplete"}
                                onChange={(addr) => { this.setState((prevState) => ({ parentInfo: { ...prevState.parentInfo, ParentAddress: addr } })) }}
                                value={this.state.parentInfo?.ParentCity ? `${this.state.parentInfo?.ParentAddress}, ${this.state.parentInfo?.ParentCity}, ${this.state.parentInfo?.ParentState} ${this.state.parentInfo?.ParentZip}` : this.state.parentInfo?.ParentStreetAddress}
                                updateFormData={(addr, city, state, zip, county, country) => {
                                  this.setState({ parentInfo: { ...this.state.parentInfo, ParentAddress: addr, ParentCity: city, ParentState: state, ParentZip: zip, ParentCounty: county } })
                                }}
                              />
                            </div>
                          </div>
                          {buildRow(this.props.t('Apartment/Suite/Building #'),
                            <input
                              id={'SecondaryAddressLine'}
                              className={"form-control"}
                              maxLength={50}
                              autoComplete={"off"}
                              type={"search"}
                              name={'SecondaryAddressLine'}
                              aria-label={this.props.t("Apartment/Suite/Building #")}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  parentInfo: {
                                    ...prevState.parentInfo,
                                    ParentAdditionalAddressInfo: e.target.value
                                  }
                                }))
                              }}
                              value={this.state.parentInfo?.ParentAdditionalAddressInfo}
                            />, this.props.t('Apartment/Suite/Building #')
                          )}
                          {buildRow(this.props.t('Email'),
                            <input
                              id={'ParentEmail'}
                              className={"form-control"}
                              maxLength={100}
                              autoComplete={"off"}
                              type={"search"}
                              name={'ParentEmail'}
                              aria-label={'Email'}
                              onChange={(e) => {
                                this.setState((prevState) => ({
                                  parentInfo: {
                                    ...prevState.parentInfo,
                                    ParentEmail: e.target.value
                                  }
                                }))
                              }}
                              value={this.state.parentInfo?.ParentEmail}
                            />, this.props.t('Parent Email')
                            , true
                          )}
                          {buildRow(this.props.t('Cell Phone'),
                            <>
                              <PhoneInput
                                id={'ParentPhone'}
                                placeholder={this.props.t("Enter phone number")}
                                onChange={(e) =>
                                  this.setState((prevState) => ({
                                    parentInfo: {
                                      ...prevState.parentInfo,
                                      ParentPhone: e
                                    }
                                  }))}
                                defaultCountry="US"
                                aria-label={this.props.t("Cell Phone")}
                                value={this.state.parentInfo?.ParentPhone} />
                              <p className='text-center'>{this.props.t("You will receive a text about the status of your immunization exemption submission")}</p>
                            </>
                            , this.props.t('Cell Phone')
                            , true
                          )}
                          <div className="form-check text-center">
                            <input className="form-check-input" type="checkbox" id="optOut"
                              checked={this.state.parentInfo?.textOptOut ? true : false}
                              onChange={(e) =>
                                this.setState((prevState) => ({
                                  parentInfo: {
                                    ...prevState.parentInfo,
                                    textOptOut: e.target.checked
                                  }
                                }))} />
                            <label className="form-check-label" htmlFor="optOut">
                              {this.props.t('Opt out of text messages about the status of my immunization exemption submission.')}
                            </label>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer" style={{ backgroundColor: 'transparent', borderTop: 'none' }}>
                    <p className='d-inline-block'><section className="text-danger d-inline-block px-1">*</section>{this.props.t("Required Fields")}</p>
                    <button className={"btn btn-primary float-right mb-3"} onClick={() => this.handleSave()}>{this.props.t("Save")}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}


export default withTranslation()(ParentInfoModal);