import NetworkUtil from "./NetworkUtil";
import Facility from "../types/Facility";
import PodSched, {PodSchedForSelect} from "../types/PodSched";
import { Patient } from "../types/Patient";
import { PasswordConfigurationValues } from "../types/PasswordConfig";

export default class AdminAPI {
    static getAllSamplesForAdminHome() {
      throw new Error("Method not implemented.");
    }

    public static async getPermissions():Promise<{isGlobalAdminorLab:boolean, isGlobal:boolean, isAdmin:boolean}>{
        return NetworkUtil.makeGet("/api/admin/permissions");
    }
    
    public static async getLabValidationTestTypes():Promise<{success:boolean, reason:string, data?}>{
        return NetworkUtil.makeGet("/api/admin/getLabValidationTestTypes");
    }

    public static async getAllResultTypes():Promise<{label:string, value:number, description:string}[]>{
        return NetworkUtil.makeGet("/api/admin/resulttypes");
    }
    public static async getAllEnabledResultTypes():Promise<{label:string, value:number, description:string}[]>{
        return NetworkUtil.makeGet("/api/admin/enabledresulttypes");
    }
    public static async getFacilitiesForAppointmentForm(active:boolean=false):Promise<{authorizedFacilities:Facility[]}>{
        return NetworkUtil.makePost("/api/admin/getFacilitiesForAppointmentForm", {active:active})
    }


    public static async getFacilitiesWithAddress(active:boolean=false):Promise<{label:string, value:number}[]>{
        return NetworkUtil.makePost("/api/admin/facilities", {active:active})
    }

    public static async getFacilitiesGroups(active:boolean=false):Promise<{label:string, value:string}>{
        return NetworkUtil.makePost("/api/admin/facilityGroups", {active:active})
    }

    public static async getBarcodeStyle():Promise<{isOneD:boolean, success:boolean, reason:string}>{
        return NetworkUtil.makeGet("/api/admin/settings/tableBarcodeStyle");
    }

    public static async getTablePlatingDirection():Promise<{isHorizontal:boolean, success:boolean, reason:string}>{
        return NetworkUtil.makeGet("/api/admin/settings/tablePlatingDirection");
    }

    public static async getAllPods(includeData:boolean=false, active:boolean=false):Promise<{success:boolean,reason:string, data:{label:string,value:(number | PodSchedForSelect)}[]}>{
        return NetworkUtil.makePost("/api/admin/pods/filter", {includedata: includeData, active: active});
    }

    public static async getSpecimenLabels(body):Promise<{success:boolean, idList:[]}>{
        return NetworkUtil.makePost("/api/admin/specimen", body)
    }

    public static async getAuthenticated(body):Promise<{success:boolean, reason:string, user:any, groups:string[]}>{
        return NetworkUtil.makePost("/api/admin/authenticated", body)
    }

    public static async createUserProfile(body):Promise<{success:boolean,reason:string,user:any}>{
        return NetworkUtil.makePost("/api/admin/createUser",body)
    }

    public static async setPswdConfig(body):Promise<{success:boolean,reason:string,data:PasswordConfigurationValues}>{
        return NetworkUtil.makePost("/api/admin/settings/setPswdConfig", body);
    }    
    
    public static async createFakeSamples(body):Promise<{success:boolean,reason:string,samples:any}>{
        return NetworkUtil.makePost("/api/admin/createFakeSamples",body)
    }

}