
export const isBlank = (str:string):boolean=> {
    return !str ||             // undefined
        str.trim().length === 0;
}

export const isOnlyWhitespace = (str:string):boolean => {
    return (  /^\s*$/.test(str) );
}

export const calculateAge = (dob:Date) =>{
    let diff_ms = Date.now() - new Date(dob).getTime();
    
    let age_dt = new Date(diff_ms);
    
    return (age_dt.getUTCFullYear() - 1970);
}

export const dateInFuture = (date: Date) => {
    return date.setHours(0,0,0,0) > new Date().setHours(0,0,0,0)
};

export const isValidEmail = (str:string):boolean => {
    // allow for multiple domain like @health.ok.gov
    return /^[^\s@]+@[^\s@]+[.][^\s@]+$/.test(str);
}